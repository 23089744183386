import React, { useState, useEffect } from 'react';
import {
  PanelHeader,
  FormLayout,
  FormLayoutGroup,
  Input,
  SelectMimicry,
  Select,
  Button,
  Spinner,
  Link,
  Checkbox,
  FormStatus,
} from '@vkontakte/vkui';
import Icon24BrowserBack from '@vkontakte/icons/dist/24/browser_back';
import useQueryFlag from '../../hooks/useQueryFlag';
import { RootRoute } from '../../router';
import vkBridge from '@vkontakte/vk-bridge';
import RegistrationModalCountries from '../atomic/modals/RegistrationModalCountries';
import { ModalId } from '../overridedVkUi/overlay/types';
import RegistrationModalCities from '../atomic/modals/RegistrationModalCities';
import { useRouteNode } from 'react-router5';
import { useSelector, useActions } from '../../hooks';
import { calculateAge } from '../../utils/math';
import { useMutation } from '@apollo/react-hooks';
import { RegisterMutation, registerMutation } from '../../types';
import { userActions } from 'src/redux/reducers/user';
import { validateForm, validationInitial } from './utils/validation';
import { ValidatedFieldsProps } from './types/types';
import { useSnackbar } from '@overrided-vkui';
import ErrorRetrySnackbar from '../atomic/snackbars/ErrorRetrySnackbar';
import { deviceActions } from 'src/redux/reducers/device';

interface RegistrationProps {
  backHandler: () => void;
}

interface RegistrationFormProps {
  selectedCity: { id: number; title: string };
  selectedCountry: { id: number; title: string };
  firstName: string;
  lastName: string;
  age: string;
  work: string;
  field: { id: string; title: string };
  email: string;
  onlineStatus: boolean;
  checkbox1: boolean;
  checkbox2: boolean;
  checkbox3: boolean;
}

const RegistrationFormPanel: React.FC<RegistrationProps> = ({ backHandler }) => {
  const setStatusbar = useActions(deviceActions.setCurrentStatusBarMode);
  const scheme = useSelector((state) => state.appConfig.scheme);
  const settings = useSelector((state) => state.settings);

  useEffect(() => {
    setStatusbar({
      mode: 'custom',
      style: {
        color: scheme === 'bright_light' ? '#fff' : '#19191a',
        style: scheme === 'bright_light' ? 'dark' : 'light',
      },
    });

    return () => {
      setStatusbar({ mode: 'default' });
    };
  }, [setStatusbar, scheme]);

  const [selectCountryModalOpened, openSelectCountryModal, closeSelectCountryModal] = useQueryFlag(
    RootRoute.REGISTRATION_FORM,
    'selectCountryModal',
  );

  const [selectCityModalOpened, openSelectCityModal, closeSelectCityModal] = useQueryFlag(
    RootRoute.REGISTRATION_FORM,
    'selectCityModal',
  );
  const fields = useSelector((state) => state.app.fields);
  const setRegisteredUser = useActions(userActions.setUser);
  const [registerUser, { loading }] = useMutation<RegisterMutation, RegisterMutation.Arguments>(registerMutation);
  const vkUser = useSelector((state) => state.vkUser);
  const openSnackbar = useSnackbar();
  const { router } = useRouteNode(RootRoute.REGISTRATION_FORM);

  const getCountries = async () => {
    const { access_token } = await vkBridge.send('VKWebAppGetAuthToken', { app_id: 7543093, scope: '' });
    const { response } = await vkBridge.send('VKWebAppCallAPIMethod', {
      method: 'database.getCountries',
      params: { v: '5.118', access_token },
    });

    setInitialCountries(response.items);
  };

  const getCities = async (countryId = 1) => {
    const { access_token } = await vkBridge.send('VKWebAppGetAuthToken', { app_id: 7543093, scope: '' });
    const { response } = await vkBridge.send('VKWebAppCallAPIMethod', {
      method: 'database.getCities',
      params: { v: '5.118', access_token, country_id: countryId },
    });

    setInitialCitites(response.items);
  };

  const openModal = async (id: ModalId) => {
    if (id === 'COUNTRY') {
      await getCountries();
      openSelectCountryModal();
    } else if (id === 'CITY') {
      await getCities(form.selectedCountry && form.selectedCountry.id ? form.selectedCountry.id : 1);
      openSelectCityModal();
    }
  };

  const [initialCountries, setInitialCountries] = useState<{ id: number; title: string }[]>([]);
  const [initialCitites, setInitialCitites] = useState<{ id: number; title: string }[]>([]);

  const getUserAge = (vkBDay: string | undefined) => {
    if (vkBDay) {
      const formatedBDay = vkBDay.split('.').reverse().map(Number);
      const [year, month, day] = formatedBDay;
      return calculateAge(new Date(year, month, day)).toString();
    } else {
      return '';
    }
  };

  const [form, setForm] = useState<RegistrationFormProps>({
    selectedCity: vkUser.city,
    selectedCountry: vkUser.country,
    firstName: vkUser.first_name,
    lastName: vkUser.last_name,
    age: getUserAge(vkUser.bdate) || '',
    work: '',
    field: { id: '', title: '' },
    email: '',
    onlineStatus: true,
    checkbox1: false,
    checkbox2: false,
    checkbox3: false,
  });

  const [validatedFields, setValidatedFields] = useState<ValidatedFieldsProps>(validationInitial);

  const onChangeCountry = (selected: { id: number; title: string }) => {
    setForm({ ...form, selectedCountry: selected, selectedCity: { id: 1, title: '' } });
  };

  const onChangeCity = (selected: { id: number; title: string }) => {
    setForm({ ...form, selectedCity: selected });
    setInitialCitites([]);
  };

  const navigateToApp = () => {
    const invite = router.getState().params?.invite;
    router.navigate(invite ? RootRoute.INVITES : RootRoute.TEAM);
  };

  const onSubmit = async () => {
    const { status, newValidatedObject } = validateForm(form);

    setValidatedFields({ ...validatedFields, ...newValidatedObject });

    if (!status) {
      return;
    }

    registerUser({
      variables: {
        params: {
          firstName: form.firstName,
          lastName: form.lastName,
          age: Number(form.age),
          city: { id: form.selectedCity.id, title: form.selectedCity.title },
          country: form.selectedCountry,
          email: form.email,
          field: { id: form.field.id, title: form.field.title },
          work: form.work,
          onlineStatus: form.onlineStatus,
          checkbox1: form.checkbox1,
          checkbox2: form.checkbox2,
          checkbox3: form.checkbox3,
        },
      },
    })
      .then((data) => {
        if (data.data?.register) {
          setRegisteredUser(data.data.register);
          navigateToApp();
        }
      })
      .catch((e) => {
        openSnackbar(<ErrorRetrySnackbar text={e.message.slice(15, -1)} />);
      });
  };
  return (
    <>
      <PanelHeader left={<Icon24BrowserBack onClick={() => backHandler()} />}>Регистрация</PanelHeader>
      <FormLayout>
        <FormLayoutGroup>
          <FormStatus header="Внимание" mode="default">
            Некоторые поля могут{' '}
            <Link href={'https://vk.com/@business-usloviya-ispolzovaniya-formy-sbora-zayavok'} target={'_blank'}>
              заполниться автоматически
            </Link>{' '}
            — теми данными, которые вы ранее указали ВКонтакте.
          </FormStatus>
        </FormLayoutGroup>
        <FormLayoutGroup
          status={validatedFields.firstName.status}
          bottom={validatedFields.firstName.status === 'error' && validatedFields.firstName.text}
          top="Имя"
        >
          <Input
            status={validatedFields.firstName.status}
            onChange={(e) => setForm({ ...form, firstName: e.target.value })}
            value={form.firstName}
            type="text"
            placeholder="Введите имя"
          />
        </FormLayoutGroup>
        <FormLayoutGroup
          status={validatedFields.lastName.status}
          bottom={validatedFields.lastName.status === 'error' && validatedFields.lastName.text}
          top="Фамилия"
        >
          <Input
            status={validatedFields.lastName.status}
            onChange={(e) => setForm({ ...form, lastName: e.target.value })}
            value={form.lastName}
            type="text"
            placeholder="Введите фамилию"
          />
        </FormLayoutGroup>
        <FormLayoutGroup
          status={validatedFields.age.status}
          bottom={validatedFields.age.status === 'error' && validatedFields.age.text}
          top="Возраст"
        >
          <Input
            form="novalidatedform"
            status={validatedFields.age.status}
            onChange={(e) => setForm({ ...form, age: e.target.value })}
            value={form.age}
            type="number"
            placeholder="Введите ваш возраст"
          />
        </FormLayoutGroup>
        <FormLayoutGroup top="Формат участия" status={validatedFields.onlineStatus.status}>
          {/*<FormStatus mode="default">Этап пройдёт только онлайн — так сейчас безопаснее</FormStatus>*/}
          <Select
            onChange={(e) => {
              setForm({ ...form, onlineStatus: !!Number(e.target.value) });
            }}
            status={validatedFields.onlineStatus.status}
          >
            <option value={1}>Онлайн</option>
            {settings?.checkboxOffline ? <option value={0}>Офлайн на площадке</option> : null}
          </Select>
        </FormLayoutGroup>
        <FormLayoutGroup
          status={validatedFields.email.status}
          bottom={validatedFields.email.status === 'error' && validatedFields.email.text}
          top="E-mail"
        >
          <Input
            status={validatedFields.email.status}
            onChange={(e) => setForm({ ...form, email: e.target.value })}
            value={form.email}
            type="text"
            placeholder="Введите вашу почту"
          />
        </FormLayoutGroup>
        <FormLayoutGroup
          status={validatedFields.selectedCountry.status}
          bottom={validatedFields.selectedCountry.status === 'error' && validatedFields.selectedCountry.text}
          top="Страна"
        >
          <SelectMimicry
            status={validatedFields.selectedCountry.status}
            onClick={() => openModal('COUNTRY')}
            top="Страна"
            placeholder="Выберите страну"
          >
            {form.selectedCountry ? form.selectedCountry.title : ''}
          </SelectMimicry>
        </FormLayoutGroup>
        <FormLayoutGroup
          status={validatedFields.selectedCity.status}
          bottom={validatedFields.selectedCity.status === 'error' && validatedFields.selectedCity.text}
          top="Город"
        >
          <SelectMimicry
            status={validatedFields.selectedCity.status}
            onClick={() => openModal('CITY')}
            top="Город"
            placeholder="Выберите город"
          >
            {form.selectedCity ? form.selectedCity.title : ''}
          </SelectMimicry>
        </FormLayoutGroup>
        <FormLayoutGroup
          status={validatedFields.work.status}
          bottom={validatedFields.work.status === 'error' && validatedFields.work.text}
          top="Место работы или учёбы"
        >
          <Input
            status={validatedFields.work.status}
            onChange={(e) => setForm({ ...form, work: e.target.value })}
            value={form.work}
            type="text"
            placeholder="Место работы или учёбы"
          />
        </FormLayoutGroup>
        <FormLayoutGroup
          status={validatedFields.field.status}
          bottom={validatedFields.field.status === 'error' && validatedFields.field.text}
          top="Специализация"
        >
          <Select
            status={validatedFields.field.status}
            onChange={(e) => {
              const choosenIndex = e.target.selectedIndex;
              const childNodes = e.target.childNodes;
              const choosenOption = childNodes[choosenIndex] as HTMLElement;
              const id = choosenOption.dataset.id!;

              setForm({ ...form, field: { id, title: e.target.value } });
            }}
          >
            <option disabled value="">
              Выберите специализацию
            </option>
            {fields.map((field) => (
              <option data-id={field.id} key={field.id} value={field.title}>
                {field.title}
              </option>
            ))}
          </Select>
        </FormLayoutGroup>
        <FormLayoutGroup
          status={validatedFields.checkbox1.status}
          bottom={validatedFields.checkbox1.status === 'error' && validatedFields.checkbox1.text}
        >
          <Checkbox onChange={(e) => setForm({ ...form, checkbox1: e.target.checked })}>
            Я читаю и принимаю{' '}
            <Link href={'https://vk.cc/cc7Qzq'} target={'_blank'}>
              Правила проведения марафона «Вездекод»
            </Link>
            . Соглашаюсь на обработку персональных данных, фотосъёмку и публикацию фотографий в СМИ и социальных сетях
            на условиях организаторов.
          </Checkbox>
        </FormLayoutGroup>
        <FormLayoutGroup>
          <Checkbox onChange={(e) => setForm({ ...form, checkbox2: e.target.checked })}>
            Хочу узнавать о подходящих вакансиях от организаторов «Вездекода» и их партнёров. Для этого поручаю передать
            мои данные на обработку партнёрам, которые обязуются использовать их в соответствии с действующим
            законодательством и собственной политикой конфиденциальности.
          </Checkbox>
        </FormLayoutGroup>
        <FormLayoutGroup>
          <Checkbox onChange={(e) => setForm({ ...form, checkbox3: e.target.checked })}>
            Хочу получать новости о мероприятиях от VK Team.
          </Checkbox>
        </FormLayoutGroup>
        <Button
          style={{ cursor: 'pointer' }}
          onClick={() => {
            onSubmit();
          }}
          size="xl"
        >
          {loading ? <Spinner style={{ color: 'var(--background_content)' }} size="small" /> : 'Зарегистрироваться'}
        </Button>
      </FormLayout>

      {initialCountries.length > 0 && (
        <RegistrationModalCountries
          modalId="COUNTRY"
          onClose={closeSelectCountryModal}
          modalOpened={selectCountryModalOpened}
          setSelected={(selected) => onChangeCountry(selected)}
          selected={form.selectedCountry}
          countries={initialCountries}
        />
      )}
      {initialCitites.length > 0 && (
        <RegistrationModalCities
          modalId="CITY"
          onClose={() => {
            closeSelectCityModal();
            setInitialCitites([]);
          }}
          modalOpened={selectCityModalOpened}
          setSelected={(selected) => onChangeCity(selected)}
          selected={form.selectedCity}
          cities={initialCitites}
          countryId={form.selectedCountry && form.selectedCountry.id ? form.selectedCountry.id : 1}
        />
      )}
    </>
  );
};

export default React.memo(RegistrationFormPanel);
