import React, { useState, useCallback, useRef } from 'react';
import { Search, List, Cell } from '@vkontakte/vkui';

import Modal from '../../overridedVkUi/Modal/Modal';

import Icon24Done from '@vkontakte/icons/dist/24/done';

import { ModalId } from '../../overridedVkUi/overlay/types';
import { throttle } from 'throttle-debounce';
import vkBridge from '@vkontakte/vk-bridge';

export interface RegistrationModalProps {
  onClose: () => void;
  modalOpened: boolean;
  modalId: ModalId;
  setSelected: (selected: { id: number; title: string }) => void;
  selected: { id: number; title: string };
  countries: { id: number; title: string }[];
}

const RegistrationModal: React.FC<RegistrationModalProps> = ({
  onClose,
  modalOpened,
  modalId,
  setSelected,
  selected,
  countries,
}) => {
  const inititalItems = useRef<{ id: number; title: string }[]>(countries);
  const allCountries = useRef<{ id: number; title: string }[]>([]);

  const [searchedItems, setSearchedItems] = useState<{ id: number; title: string }[]>(countries);
  const [search, setSearch] = useState<string>('');

  const [queryMode, setQueryMode] = useState<boolean>(false);
  const searchRef = useRef<any>(null);

  const getCountriesByQuery: any = useCallback(
    throttle(1500, async () => {
      const { access_token } = await vkBridge.send('VKWebAppGetAuthToken', { app_id: 7543093, scope: '' });
      const { response } = await vkBridge.send('VKWebAppCallAPIMethod', {
        method: 'database.getCountries',
        params: { v: '5.118', access_token, need_all: 1, count: 234 },
      });

      const items = response.items as { id: number; title: string }[];
      console.log(search);

      if (search.trim().length > 0) {
        setSearchedItems(items.filter(({ title }) => title.toLowerCase().includes(search.toLowerCase())));
      } else {
        setSearchedItems(response.items);
      }
      allCountries.current = response.items;
    }),
    [search],
  );

  const onDifferentCountryHandler = () => {
    searchRef.current.focus();
    setSearchedItems([]);
    setQueryMode(true);
    getCountriesByQuery();
  };

  const throttledSetAllCountires = useCallback(
    throttle(800, (search: string) => {
      setSearchedItems(allCountries.current.filter(({ title }) => title.toLowerCase().includes(search.toLowerCase())));
    }),
    [],
  );

  const onSearchHandler = async (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);

    if (queryMode && e.target.value.trim().length > 0 && allCountries.current) {
      throttledSetAllCountires(e.target.value);
    }
    if (queryMode && e.target.value.trim().length < 1 && allCountries.current) {
      setSearchedItems(allCountries.current);
    }

    if (!queryMode) {
      if (e.target.value.trim().length > 0 && inititalItems.current) {
        setSearchedItems(
          inititalItems.current.filter(({ title }) => title.toLowerCase().includes(e.target.value.toLowerCase())),
        );
      } else if (e.target.value.trim().length < 1 && inititalItems.current) {
        setSearchedItems(inititalItems.current);
      }
    }
  };
  /*   setSearch(e.target.value);
  setSearchedItems(
    e.target.value.trim().length > 0
      ? inititalItems.filter(({ title }) => title.toLowerCase().includes(e.target.value.toLowerCase()))
      : inititalItems,
  ); */
  return (
    <Modal
      id={modalId}
      show={modalOpened}
      onClose={onClose}
      title={'Страна'}
      fullHeight
      headerShaddow="never"
      subHeader={<Search getRef={searchRef} value={search} onChange={onSearchHandler} />}
    >
      <List>
        {searchedItems.map((country) => (
          <Cell
            onClick={() => {
              setSelected(country);
              onClose();
            }}
            asideContent={
              country && selected && country.title === selected.title ? <Icon24Done fill="var(--accent)" /> : null
            }
            key={country.id}
          >
            {country.title}
          </Cell>
        ))}
        {!queryMode && <Cell onClick={onDifferentCountryHandler}>Другая</Cell>}
      </List>
    </Modal>
  );
};

export default React.memo(RegistrationModal);
