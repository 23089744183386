import React from 'react';
import { RootRoute } from '../../router';
import View, { ViewProps } from '@vkontakte/vkui/dist/components/View/View';
import { Panel } from '@vkontakte/vkui';
import { useRouteNode } from 'react-router5';
import RegistrationPanel from '../panels/RegistrationPanel';
import RegistrationFormPanel from '../panels/RegistrationFormPanel';

const RegistrationView: React.FC<Omit<ViewProps, 'activePanel'>> = (viewProps) => {
  const { router, route } = useRouteNode(RootRoute.REGISTRATION);

  const navigateToForm = () => {
    router.navigate(RootRoute.REGISTRATION_FORM, { ...route.params });
  };

  const backHandler = () => {
    router.navigate(RootRoute.REGISTRATION);
  };

  const activeRoute = route.name;

  return (
    <View {...viewProps} activePanel={activeRoute}>
      <Panel id={RootRoute.REGISTRATION}>
        <RegistrationPanel onBtnClick={navigateToForm} />
      </Panel>
      <Panel id={RootRoute.REGISTRATION_FORM}>
        <RegistrationFormPanel backHandler={backHandler} />
      </Panel>
    </View>
  );
};

export default React.memo(RegistrationView);
