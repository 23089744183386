import React, { useState, useEffect } from 'react';
import { PanelHeader, Text, List, PullToRefresh } from '@vkontakte/vkui';
import { makeStyles } from '@material-ui/styles';
import { Theme } from 'src/theme';
import { useSnackbar } from '@overrided-vkui';

import { useQuery } from '@apollo/react-hooks';

import { Team } from 'src/types/gql';

import { AppLoadingView } from '../views/AppLoadingView';
import ErrorRetrySnackbar from '../atomic/snackbars/ErrorRetrySnackbar';

import { TeamsByInviteQuery, teamsByInviteQuery } from 'src/types/gql/teamsByInviteQuery';
import Icon24NotificationCheckOutline from '@vkontakte/icons/dist/24/notification_check_outline';
import InviteCard from '../atomic/InviteCard/InviteCard';
import { useSelector } from 'src/hooks';

const styles = makeStyles(
  (theme: Theme) => ({
    root: {
      position: 'relative',
      marginTop: 7,
    },
    header: {
      '& .PanelHeader__fixed': {
        backgroundColor: theme.palette.header.background,
      },
      '& .PanelHeader__content': {
        color: theme.palette.header.color,
      },
    },
    noTeamContainer: {
      position: 'fixed',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      height: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flexDirection: 'column',
      '& .Text': {
        color: 'var(--attach_picker_tab_inactive_text)',
        fontSize: 16,
        textAlign: 'center',
        marginBottom: 24,
      },
    },
    createTeamBtn: {
      width: '100%',
      height: 44,
    },
    noInvitesIcon: {
      color: 'var(--icon_outline_secondary)',
      marginBottom: 17,
      '& svg': {
        width: 36,
        height: 37,
      },
    },
  }),
  { classNamePrefix: 'InvitesPanel' },
);

const InvitesPanel = () => {
  const classes = styles();

  const { data: userInvitesData, loading, error, refetch, networkStatus } = useQuery<
    TeamsByInviteQuery,
    TeamsByInviteQuery.Arguments
  >(teamsByInviteQuery, {
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
  });
  const vkUser = useSelector((state) => state.vkUser);
  const openSnackbar = useSnackbar();
  const [invites, setInvites] = useState<Team[]>([]);

  useEffect(() => {
    if (userInvitesData && userInvitesData.teamsByInvite) {
      setInvites(userInvitesData.teamsByInvite);
    } else if (error) {
      openSnackbar(React.createElement(ErrorRetrySnackbar, { text: error.message } as any));
    }
  }, [userInvitesData, loading, error, openSnackbar]);

  const onAcceptOrDenyInviteHandler = () => {
    refetch();
  };

  const isUserAlreadyInTeam = invites.some((invite) => invite.users.some((user) => user.vkUserId === vkUser.id));

  const noInvites = (
    <>
      <PanelHeader separator={false} className={classes.header}>
        Приглашения
      </PanelHeader>
      <div className={classes.noTeamContainer}>
        <Icon24NotificationCheckOutline className={classes.noInvitesIcon} />
        <Text weight="regular">Еще нет приглашений в команду</Text>
      </div>
    </>
  );

  if (loading && networkStatus !== 4) {
    return <AppLoadingView />;
  } else if (userInvitesData && userInvitesData.teamsByInvite.length > 0) {
    return (
      <>
        <PanelHeader separator={false} className={classes.header}>
          Приглашения
        </PanelHeader>
        <PullToRefresh onRefresh={() => refetch()} isFetching={loading}>
          <List style={{ marginTop: 12 }}>
            {invites.map((team) => (
              <InviteCard
                isAlreadyInTeam={isUserAlreadyInTeam}
                accepted={vkUser && team.users.some((user) => user.vkUserId === vkUser.id)}
                onAcceptOrDenyHandler={onAcceptOrDenyInviteHandler}
                key={team.id}
                teamTitle={team.name}
                teamId={team.id}
                teamUsers={team.users}
                captainId={team.captain}
              />
            ))}
          </List>
        </PullToRefresh>
      </>
    );
  } else return noInvites;
};

export default React.memo(InvitesPanel);
