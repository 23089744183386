import React, { useState, useEffect } from 'react';
import { PanelHeader, Div, Text, Button, Input, PullToRefresh } from '@vkontakte/vkui';
import { makeStyles } from '@material-ui/styles';
import { Theme } from 'src/theme';
import { Modal, useSnackbar } from '@overrided-vkui';
import useQueryFlag from 'src/hooks/useQueryFlag';
import { RootRoute } from 'src/router';
import TeamCard from '../atomic/TeamCard/TeamCard';

import { useQuery, useMutation } from '@apollo/react-hooks';
import { TeamQuery, teamQuery } from 'src/types/gql/teamQuery';
import { Team } from 'src/types/gql';
import { CreateTeamMutation, createTeamMutation } from 'src/types';
import { AppLoadingView } from '../views/AppLoadingView';
import ErrorRetrySnackbar from '../atomic/snackbars/ErrorRetrySnackbar';
import Icon28EditOutline from '@vkontakte/icons/dist/28/edit_outline';

//plus margin
export const FOOTER_BTNS_HEIGHT = '117px';
const styles = makeStyles(
  (theme: Theme) => ({
    root: {
      position: 'relative',
      marginTop: 7,
      minHeight: `calc(100vh - ${FOOTER_BTNS_HEIGHT} - var(--safe-area-inset-top) - var(--safe-area-inset-bottom))`,
    },
    header: {
      '& .PanelHeader__fixed': {
        backgroundColor: theme.palette.header.background,
      },
      '& .PanelHeader__left': {
        flexGrow: 0,
      },
      '& .PanelHeader__content': {
        color: theme.palette.header.color,
        paddingLeft: 4,
      },
    },
    noTeamContainer: {
      position: 'fixed',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      height: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flexDirection: 'column',
      '& .Text': {
        color: 'var(--attach_picker_tab_inactive_text)',
        fontSize: 16,
        textAlign: 'center',
        marginBottom: 24,
      },
    },
    createTeamBtn: {
      width: '100%',
      height: 44,
    },
    editIcon: {
      '&:active': {
        opacity: 0.7,
      },
    },
  }),
  { classNamePrefix: 'TeamPanel' },
);

const TeamPanel = () => {
  const classes = styles();
  const [addTeamModalOpened, openAddTeamModal, closeAddTeamModal] = useQueryFlag(RootRoute.TEAM, 'addTeamModal');
  const [editTeamNameModalOpened, openEditTeamNameModal, closeEditTeamModal] = useQueryFlag(
    RootRoute.TEAM,
    'editTeamNameModal',
  );

  const { data: userTeamData, loading, error, refetch, networkStatus } = useQuery<TeamQuery, TeamQuery.Arguments>(
    teamQuery,
    {
      fetchPolicy: 'network-only',
      notifyOnNetworkStatusChange: true,
    },
  );

  const [createTeamRemote, { loading: createTeamLoading }] = useMutation<
    CreateTeamMutation,
    CreateTeamMutation.Arguments
  >(createTeamMutation);
  const openSnackbar = useSnackbar();

  const [team, setTeam] = useState<Team | null>(null);
  const [editMode, setEditMode] = useState(false);

  useEffect(() => {
    if (userTeamData && userTeamData.team) {
      setTeam(userTeamData.team);
    } else if (error) {
      openSnackbar(React.createElement(ErrorRetrySnackbar, { text: error.message } as any));
    }
  }, [userTeamData, loading, error, openSnackbar]);

  const [teamName, setTeamName] = useState('');

  const createTeamHandler = async () => {
    await createTeamRemote({
      variables: {
        params: {
          name: teamName,
        },
      },
    })
      .then((data) => {
        if (data.data?.createTeam) {
          refetch();
          closeAddTeamModal();
          setTeamName('');
        }
      })
      .catch((e) => openSnackbar(<ErrorRetrySnackbar text={e.message} />));
  };

  const editModeHandler = () => {
    setEditMode(!editMode);
  };

  const refetchHandler = () => {
    refetch();
  };

  const createTeamModal = (
    <Modal
      id="ADD_TEAM"
      show={addTeamModalOpened}
      onClose={closeAddTeamModal}
      title="Название команды"
      headerShaddow="never"
    >
      <Div>
        <Input
          value={teamName}
          onChange={(e) => setTeamName(e.target.value)}
          style={{ marginBottom: 12 }}
          placeholder="Название команды"
          maxLength={28}
        />
        <Button
          disabled={teamName.trim().length < 1}
          onClick={createTeamHandler}
          className={classes.createTeamBtn}
          size="l"
        >
          {createTeamLoading ? 'Создаю...' : 'Создать'}
        </Button>
      </Div>
    </Modal>
  );

  const noTeam = (
    <>
      <PanelHeader separator={false} className={classes.header}>
        Команда
      </PanelHeader>
      <div className={classes.noTeamContainer}>
        <Text weight="regular">
          Вы еще не состоите в команде.
          <br /> Создайте свою, либо ожидайте <br /> приглашения.
        </Text>
        <Button onClick={() => openAddTeamModal()} size="m">
          Создать команду
        </Button>
        {createTeamModal}
      </div>
    </>
  );

  if (loading && !team && networkStatus !== 4) {
    return <AppLoadingView />;
  } else if (!loading && userTeamData && !userTeamData.team) {
    return noTeam;
  } else {
    return (
      <>
        <PanelHeader
          left={
            editMode && (
              <>
                <Icon28EditOutline
                  onClick={openEditTeamNameModal}
                  className={classes.editIcon}
                  style={{ color: 'white', marginLeft: 10 }}
                />
              </>
            )
          }
          separator={false}
          className={classes.header}
        >
          {team && team.name}
        </PanelHeader>
        <PullToRefresh onRefresh={() => refetch()} isFetching={loading}>
          <div className={classes.root}>
            {team && (
              <TeamCard
                editTeamNameModalOpened={editTeamNameModalOpened}
                closeEditTeamModal={closeEditTeamModal}
                editMode={editMode}
                onRefetch={refetchHandler}
                editModeHandler={editModeHandler}
                teamId={team.id}
                name={team.name}
                usersQty={team.users.length}
                maxUsersQty={team.maxUsersCount}
                users={team.users.sort((a) => (a.vkUserId === team.captain ? -1 : 1))}
                captainId={team.captain}
                invitedUsers={team.invited.filter(
                  (invitedUser) => !team.users.some((user) => invitedUser.vkUserId === user.vkUserId),
                )}
              />
            )}
          </div>
        </PullToRefresh>

        {createTeamModal}
      </>
    );
  }
};

export default React.memo(TeamPanel);
