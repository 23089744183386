import React, { useCallback } from 'react';
import { FixedLayout } from '@vkontakte/vkui';
import { makeStyles } from '@material-ui/styles';
import Icon28UsersOutline from '@vkontakte/icons/dist/28/users_outline';
import Icon28Notifications from '@vkontakte/icons/dist/28/notifications';
import Icon28UserCircleOutline from '@vkontakte/icons/dist/28/user_circle_outline';
import { useRoute } from 'react-router5';
import { RootRoute } from '../../router';
import clsx from 'clsx';
import { throttle } from 'throttle-debounce';
import Icon28GhostOutline from '@vkontakte/icons/dist/28/ghost_outline';
import { useSelector } from 'src/hooks';
import { Insets } from '@vkontakte/vk-bridge';

const styles = makeStyles(
  {
    tabsContainer: {
      height: 48,
      zIndex: 99,
      borderTop: '1px solid var(--background_keyboard)',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-around',
      backgroundColor: 'var(--background_content)',
    },
    tabsItem: {
      flex: '1 0 0',
      display: 'flex',
      justifyContent: 'center',
      cursor: 'pointer',
    },
    tabsItemIcon: {
      padding: '12px 20px',
      color: 'var(--content_placeholder_icon)',
      '&:hover': {
        color: 'var(--tabbar_active_icon)',
      },
    },
    tabsItemIconActive: {
      color: 'var(--tabbar_active_icon)',
    },
    fixedLayout: {
      zIndex: 99,
      backgroundColor: 'var(--background_content)',
      paddingBottom: (props: { insets: Insets }) => `${props.insets.bottom}px`,
    },
  },
  { classNamePrefix: 'Epic' },
);
const Epic = () => {
  const { router, route } = useRoute();
  const insets = useSelector((state) => state.device.currentInsets);
  const user = useSelector((state) => state.user);

  const activeView = route.name.split('.')[0];

  const classes = styles({ insets });

  const throttledNavigate = useCallback(
    (path: RootRoute) => {
      if (path === RootRoute.TEAM) {
        router.navigate(RootRoute.TEAM);
      } else if (path === RootRoute.INVITES) {
        router.navigate(RootRoute.INVITES);
      } else if (path === RootRoute.ADMIN) {
        router.navigate(RootRoute.ADMIN);
      } else {
        router.navigate(RootRoute.PROFILE);
      }
    },
    [activeView],
  );
  return (
    <FixedLayout className={classes.fixedLayout} vertical="bottom">
      <div className={classes.tabsContainer}>
        <div className={classes.tabsItem}>
          <Icon28UsersOutline
            onClick={() => throttledNavigate(RootRoute.TEAM)}
            className={clsx(classes.tabsItemIcon, activeView === RootRoute.TEAM && classes.tabsItemIconActive)}
          />
        </div>
        <div className={classes.tabsItem}>
          <Icon28Notifications
            onClick={() => throttledNavigate(RootRoute.INVITES)}
            className={clsx(classes.tabsItemIcon, activeView === RootRoute.INVITES && classes.tabsItemIconActive)}
          />
        </div>
        <div className={classes.tabsItem}>
          <Icon28UserCircleOutline
            onClick={() => throttledNavigate(RootRoute.PROFILE)}
            className={clsx(classes.tabsItemIcon, activeView === RootRoute.PROFILE && classes.tabsItemIconActive)}
          />
        </div>
        {user && user.isAdmin && (
          <div className={classes.tabsItem}>
            <Icon28GhostOutline
              onClick={() => throttledNavigate(RootRoute.ADMIN)}
              className={clsx(classes.tabsItemIcon, activeView === RootRoute.ADMIN && classes.tabsItemIconActive)}
            />
          </div>
        )}
      </div>
    </FixedLayout>
  );
};

export default React.memo(Epic);
