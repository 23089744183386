import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Theme } from 'src/theme';
import { Title, Avatar, Button, CellButton, Div, Input, Cell, FixedLayout } from '@vkontakte/vkui';
import Icon28AddOutline from '@vkontakte/icons/dist/28/add_outline';
import CreateInvitesModal from '../modals/CreateInvitesModal';
import useQueryFlag from 'src/hooks/useQueryFlag';
import { RootRoute } from 'src/router';
import { useSelector } from 'src/hooks';
import { Modal, useSnackbar } from '@overrided-vkui';
import { useMutation } from '@apollo/react-hooks';
import {
  UpdateTeamMutation,
  updateTeamMutation,
  LeaveTeamMutation,
  leaveTeamMutation,
  DeleteTeamMutation,
  deleteTeamMutation,
  KickUserMutation,
  kickUserMutation,
} from 'src/types';
import ErrorRetrySnackbar from '../snackbars/ErrorRetrySnackbar';
import QuitTeamAlert from '../alerts/QuitTeamAlert';
import DeleteTeamAlert from '../alerts/DeleteTeamAlert';
import SuccessSnackbar from '../snackbars/SuccessSnackbar';
import { FOOTER_BTNS_HEIGHT } from 'src/components/panels/TeamPanel';
import { Insets } from '@vkontakte/vk-bridge';
import { ReactComponent as CaptainIcon } from '../../../assets/starIcon.svg';
import { AppLoadingView } from 'src/components/views/AppLoadingView';

const styles = makeStyles(
  (theme: Theme) => ({
    inviteUsersContainer: {
      display: 'flex',
      alignItems: 'center',
      cursor: 'pointer',
      '& .Button__content': {
        cursor: 'pointer',
      },
    },
    simpleCell: {
      '& .Cell__children': {
        fontFamily: theme.typography.fontFamilyTT,
        fontWeight: 500,
      },
    },
    addUserIconContainer: {
      backgroundColor: 'var(--button_secondary_background)',
      width: 48,
      height: 48,
      borderRadius: '50%',
      overflow: 'hidden',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    captainStarContainer: {
      backgroundColor: 'var(--accent)',
      width: '20 !important',
      height: '20 !important',
      borderRadius: '50%',
      overflow: 'hidden',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      padding: '0 !important',
      '&  svg': {
        width: 14,
        height: 14,
      },
    },
    createTeamBtn: {
      width: '100%',
      height: 44,
    },
    footerBtnsContainer: {
      bottom: (props: { insets: Insets }) => `${props.insets.bottom + 52}px !important`,
      backgroundColor: 'var(--background_content)',
      zIndex: 2,
      width: '100%',
      '& .CellButton__content': {
        cursor: 'pointer',
      },
    },
    captainIcon: {
      '& .outer': {
        fill: 'var(--accent)',
      },
    },
  }),
  { classNamePrefix: 'TeamCard' },
);

interface User {
  vkUserId: number;
  firstName: string;
  lastName: string;
  photo: string;
  field: { id: string; title: string };
}
interface TeamProps {
  usersQty: number;
  maxUsersQty: number;
  name: string;
  users: User[];
  captainId: number;
  invitedUsers: User[];
  teamId: string;
  editMode: boolean;
  onRefetch: () => void;
  editModeHandler: () => void;
  closeEditTeamModal: () => void;
  editTeamNameModalOpened: boolean;
}
const TeamCard: React.FC<TeamProps> = ({ ...props }) => {
  const {
    usersQty,
    maxUsersQty,
    users,
    captainId,
    invitedUsers,
    name,
    teamId,
    onRefetch,
    editMode,
    editModeHandler,
    editTeamNameModalOpened,
    closeEditTeamModal,
  } = props;
  const vkUser = useSelector((state) => state.vkUser);
  const insets = useSelector((state) => state.device.insets);
  const classes = styles({ insets });
  const [updateTeamRemote, { loading: editTeamNameLoading }] = useMutation<
    UpdateTeamMutation,
    UpdateTeamMutation.Arguments
  >(updateTeamMutation);

  const [kickUserRemote, { loading: kickUserLoading }] = useMutation<KickUserMutation, KickUserMutation.Arguments>(
    kickUserMutation,
  );

  const [quitTeamRemote, { loading: quitTeamLoading }] = useMutation<LeaveTeamMutation, LeaveTeamMutation.Arguments>(
    leaveTeamMutation,
  );
  const [deleteTeamRemote, { loading: deleteTeamLoading }] = useMutation<
    DeleteTeamMutation,
    DeleteTeamMutation.Arguments
  >(deleteTeamMutation);
  const [createInvitesModalOpened, openCreateInvitesModal, closeCreateInvitesModal] = useQueryFlag(
    RootRoute.TEAM,
    'createInvitesModal',
  );

  const [quitTeamOpened, openQuitTeamAlert, closeQuitTeamAlert] = useQueryFlag(RootRoute.TEAM, 'quitTeamAlert');
  const [deleteTeamOpened, openDeleteTeamAlert, closeDeleteTeamAlert] = useQueryFlag(RootRoute.TEAM, 'deleteTeamAlert');

  const [friendsFetchStatus, setFetchStatus] = useState(false);
  const [teamName, setTeamName] = useState(name);
  const openSnackbar = useSnackbar();

  const inviteUsersHandler = () => {
    if (usersQty >= maxUsersQty) {
      return;
    } else if (usersQty + invitedUsers.length >= maxUsersQty) {
      openSnackbar(<ErrorRetrySnackbar text="Вы пригласили максимальное кол-во участников" />);
      return;
    } else {
      openCreateInvitesModal();
      setFetchStatus(true);
    }
  };

  useEffect(() => {
    if (name !== teamName) {
      setTeamName(name);
    }
  }, [name]);

  const fetchFriendsStatusHandler = (status: boolean) => {
    setFetchStatus(!status);
  };

  const editTeamNameHandler = () => {
    updateTeamRemote({ variables: { params: { name: teamName } } })
      .then((data) => {
        if (data.data?.updateTeam) {
          onRefetch();
          closeEditTeamModal();
        }
      })
      .catch((e) => {
        openSnackbar(<ErrorRetrySnackbar text={e.message} />);
      });
  };

  const quitTeamHandler = (teamId: string) => {
    quitTeamRemote({
      variables: {
        teamId,
      },
    })
      .then((data) => {
        if (data.data?.leaveTeam) {
          onRefetch();
        }
      })
      .catch((e) => openSnackbar(<ErrorRetrySnackbar text={e.message} />));
  };

  const deleteTeamHandler = () => {
    deleteTeamRemote()
      .then((data) => {
        if (data.data?.deleteTeam) {
          onRefetch();
        }
      })
      .catch((e) => openSnackbar(<ErrorRetrySnackbar text={e.message} />));
  };

  const kickUserHandler = (id: number, fromInvited = false) => {
    kickUserRemote({
      variables: {
        vkUserId: id,
      },
    })
      .then((data) => {
        if (data.data?.kickUser) {
          onRefetch();
          openSnackbar(<SuccessSnackbar text={fromInvited ? 'Приглашение отозвано' : 'Член команды успешно удален'} />);
        } else {
          openSnackbar(<ErrorRetrySnackbar text={fromInvited ? 'Не удалось отозвать' : 'Не удалось удалить'} />);
        }
      })
      .catch((e) => {
        openSnackbar(<ErrorRetrySnackbar text={e.message} />);
      });
  };
  const editTeamNameModal = (
    <Modal
      id="EDIT_TEAM_NAME"
      show={editTeamNameModalOpened}
      onClose={closeEditTeamModal}
      title="Название команды"
      headerShaddow="never"
    >
      <Div>
        <Input
          value={teamName}
          onChange={(e) => setTeamName(e.target.value)}
          style={{ marginBottom: 12 }}
          placeholder="Название команды"
          maxLength={28}
        />
        <Button
          disabled={teamName.trim().length < 1}
          onClick={editTeamNameHandler}
          className={classes.createTeamBtn}
          size="l"
        >
          {editTeamNameLoading ? 'Сохраняю...' : 'Сохранить'}
        </Button>
      </Div>
    </Modal>
  );

  return (
    <div style={{ paddingBottom: FOOTER_BTNS_HEIGHT }}>
      {(kickUserLoading || quitTeamLoading || deleteTeamLoading) && <AppLoadingView />}
      <Div>
        <Title weight="semibold" level="2">{`Участники (${usersQty} из ${maxUsersQty})`}</Title>
      </Div>
      {users.map((user) => (
        <Cell
          className={classes.simpleCell}
          key={user.vkUserId}
          before={<Avatar size={48} src={user.photo} />}
          removable={editMode && captainId !== user.vkUserId}
          onRemove={() => kickUserHandler(user.vkUserId)}
          asideContent={captainId === user.vkUserId ? <CaptainIcon className={classes.captainIcon} /> : null}
          description={user.field.title}
        >{`${user.firstName} ${user.lastName}`}</Cell>
      ))}
      {usersQty < maxUsersQty && vkUser && vkUser.id === captainId && (
        <Div onClick={inviteUsersHandler} className={classes.inviteUsersContainer}>
          <div className={classes.addUserIconContainer}>
            <Icon28AddOutline style={{ color: 'var(--accent)' }} />
          </div>
          <Button mode="tertiary">Добавить участников</Button>
        </Div>
      )}
      <Div>
        <Title style={{ fontSize: 17 }} weight="semibold" level="2">
          Ожидается ответ
        </Title>
      </Div>
      {invitedUsers.length > 0 ? (
        invitedUsers.map((invitedUser) => (
          <Cell
            removable={editMode}
            onRemove={() => kickUserHandler(invitedUser.vkUserId, true)}
            className={classes.simpleCell}
            key={invitedUser.vkUserId}
            before={<Avatar size={48} src={invitedUser.photo} />}
            description={invitedUser.field.title}
          >{`${invitedUser.firstName} ${invitedUser.lastName}`}</Cell>
        ))
      ) : (
        <Div style={{ paddingTop: 0, color: 'var(--attach_picker_tab_inactive_text)' }}>
          Нет отправленных приглашений
        </Div>
      )}

      <CreateInvitesModal
        onInviteHandler={onRefetch}
        onClose={closeCreateInvitesModal}
        modalOpened={createInvitesModalOpened}
        modalId="CREATE_INVITES"
        fetchStatus={friendsFetchStatus}
        fetchStatusHandler={fetchFriendsStatusHandler}
        currentUsersQty={usersQty + invitedUsers.length}
        maxUsersQty={maxUsersQty}
      />

      <FixedLayout className={classes.footerBtnsContainer} vertical="bottom">
        {vkUser && vkUser.id === captainId && (
          <>
            <CellButton onClick={editModeHandler} mode="primary">
              {editMode ? 'Готово' : 'Редактирование команды'}
            </CellButton>
            <CellButton onClick={openDeleteTeamAlert} mode="danger">
              Удалить команду
            </CellButton>
            {editTeamNameModal}
            <DeleteTeamAlert show={deleteTeamOpened} onClose={closeDeleteTeamAlert} onDelete={deleteTeamHandler} />
          </>
        )}
        {vkUser && vkUser.id !== captainId && (
          <>
            <CellButton onClick={openQuitTeamAlert} mode="danger">
              Выйти из команды
            </CellButton>
            <QuitTeamAlert
              show={quitTeamOpened}
              onClose={closeQuitTeamAlert}
              onTeamQuit={() => quitTeamHandler(teamId)}
            />
          </>
        )}
      </FixedLayout>
    </div>
  );
};

export default React.memo(TeamCard);
