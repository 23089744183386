import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';

import { useDoubleTapHandler, useSelector } from '../../hooks';
import Root from '@vkontakte/vkui/dist/components/Root/Root';

import { useRouteNode } from 'react-router5';
import TeamView from '../views/TeamView';
import { RootRoute } from '../../router';
import InvitesView from '../views/InvitesView';
import ProfileView from '../views/ProfileView';
import Epic from '../Epic';
import RegistrationView from '../views/RegistrationView';
import AdminView from '../views/AdminView';
import RegistrationErrorView from '../views/RegistrationErrorView';
import AppOffView from '../views/AppOffView';

const styles = makeStyles(() => ({
  root: {
    '&::-webkit-scrollbar': {
      display: 'none',
    },
  },
}));

/**
 * Visual entry of application
 * @type {React.NamedExoticComponent<object>}
 */
const App = () => {
  const classes = styles();
  document.body.className = classes.root;

  const onTouchStart = useDoubleTapHandler();

  // Add handler which prevents scroll after double tapping the screen
  useEffect(() => {
    const body = document.documentElement;
    body.addEventListener('touchstart', onTouchStart, { passive: false });

    return () => body.removeEventListener('touchstart', onTouchStart);
  }, [onTouchStart]);

  const user = useSelector((state) => state.user);

  const { route } = useRouteNode('');
  const activeView = route.name.split('.')[0];

  const epic =
    activeView !== RootRoute.REGISTRATION &&
    activeView !== RootRoute.REGISTRATION_ERROR &&
    activeView !== RootRoute.APP_OFF;

  return (
    <>
      <Root activeView={activeView}>
        <RegistrationView id={RootRoute.REGISTRATION} />
        <TeamView id={RootRoute.TEAM} />
        <InvitesView id={RootRoute.INVITES} />
        <ProfileView id={RootRoute.PROFILE} />
        <AdminView id={RootRoute.ADMIN} />
        <RegistrationErrorView id={RootRoute.REGISTRATION_ERROR} />
        <AppOffView id={RootRoute.APP_OFF} />
      </Root>
      {user && epic && <Epic />}
    </>
  );
};

export default React.memo(App);
