import { ofType, unionize, UnionOf } from 'unionize';
import { unionizeConfig } from '../utils';
import { Settings } from 'src/types';

export type SettingsReducerState = Settings | null;

export const settingsActions = unionize(
  {
    setSettings: ofType<Settings>(),
  },
  unionizeConfig,
);

type SettingsActions = UnionOf<typeof settingsActions>;

const initialState: SettingsReducerState = null;

export function settingsReducer(state: SettingsReducerState = initialState, action: SettingsActions) {
  return settingsActions.match(action, {
    setSettings: (settings) => ({
      ...state,
      ...settings,
    }),
    default: () => state,
  });
}
