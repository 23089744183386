import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';
import View, { ViewProps } from '@vkontakte/vkui/dist/components/View/View';
import { Title, Div, Text, Link, Panel } from '@vkontakte/vkui';

import { useActions, useSelector } from '../../hooks';
import { Theme } from '../../theme';
import { deviceActions } from '../../redux/reducers/device';
import { RootRoute } from '../../router';

import BackgroundSVG from '../../assets/onboarding-background.svg';
import VkSVG from '../../assets/vk.svg';

const styles = makeStyles(
  (theme: Theme) => ({
    root: {
      height: '100%',
      width: '100%',
      backgroundColor: '#1C3738',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'contain',
      backgroundPosition: 'center center',
      overflow: 'hidden',
      display: 'flex',
      alignItems: 'center',
      position: 'fixed',
      top: 0,
      right: 0,
      left: 0,
      bottom: 0,
    },
    background: {
      position: 'absolute',
      zIndex: -1,
      height: '100%',
      width: '100%',
      objectFit: 'cover',
    },
    centerBlock: {
      paddingTop: 40,
    },
    title: {
      color: 'white',
      fontSize: 53,
      fontWeight: 400,
      lineHeight: '66px',
      fontFamily: 'VK Sans Display',
    },
    text: {
      color: 'white',
      fontSize: 18,
      lineHeight: '22px',
      fontFamily: 'VK Sans Display',
      marginTop: '6vh',
      marginBottom: '4vh',
    },
    logo: {
      position: 'absolute',
      bottom: '8vh',
      height: '44px',
      width: '195px',
      backgroundImage: `url(${VkSVG})`,
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'contain',
      backgroundPosition: 'center center',
    },
  }),
  { classNamePrefix: 'registrationError' },
);

const AppOffView: React.FC<Omit<ViewProps, 'activePanel'>> = (viewProps) => {
  const setStatusbar = useActions(deviceActions.setCurrentStatusBarMode);
  const insets = useSelector((state) => state.device.currentInsets);
  const settings = useSelector((state) => state.settings);

  useEffect(() => {
    setStatusbar({ mode: 'custom', style: { color: '#000000', style: 'light' } });
  }, [setStatusbar]);

  const classes = styles({ insets });
  return (
    <View {...viewProps} activePanel={RootRoute.APP_OFF}>
      <Panel id={RootRoute.APP_OFF}>
        <div className={classes.root}>
          <img className={classes.background} src={settings?.registerImg || BackgroundSVG} alt="" />
          <Div className={classes.centerBlock}>
            <Title className={classes.title} level="1" weight="bold">
              вездекод
            </Title>
            <Text className={classes.text} weight="regular">
              {settings?.appTitle || 'Приложение выключено'}
              <br />
              <Link href={settings?.registerLink || 'https://vk.com/vezdekod'} target="_blank">
                {settings?.appDescription || 'Подробности Вы всегда можете найти в нашем сообществе'}
              </Link>
            </Text>
            {/*<div className={classes.logo} />*/}
          </Div>
        </div>
      </Panel>
    </View>
  );
};

export default React.memo(AppOffView);
