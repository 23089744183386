import React from 'react';
import View, { ViewProps } from '@vkontakte/vkui/dist/components/View/View';
import { Panel } from '@vkontakte/vkui';
import ProfilePanel from '../panels/ProfilePanel';
import { makeStyles } from '@material-ui/styles';
import { useSelector } from 'src/hooks';
import { Insets } from '@vkontakte/vk-bridge';

const styles = makeStyles(
  {
    root: {
      '& .Panel__in': {
        paddingBottom: (props: { insets: Insets }) => {
          console.log(props.insets.bottom);
          return `${props.insets.bottom + 52}px !important`;
        },
      },
    },
  },
  { classNamePrefix: 'profile' },
);
const ProfileView: React.FC<Omit<ViewProps, 'activePanel'>> = (viewProps) => {
  const insets = useSelector((state) => state.device.currentInsets);

  const classes = styles({ insets });

  return (
    <View {...viewProps} activePanel="profile.panel">
      <Panel className={classes.root} id="profile.panel">
        <ProfilePanel />
      </Panel>
    </View>
  );
};

export default React.memo(ProfileView);
