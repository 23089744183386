/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { RichCell, Avatar, UsersStack, Button, Spinner } from '@vkontakte/vkui';
import { User, InviteActionMutation, inviteActionMutation } from 'src/types';
import { declOfNum } from 'src/utils/math';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import { useMutation } from '@apollo/react-hooks';
import Icon16Done from '@vkontakte/icons/dist/16/done';
import { useSnackbar } from '@overrided-vkui';
import ErrorRetrySnackbar from '../snackbars/ErrorRetrySnackbar';

interface InviteCardProps {
  teamId: any;
  teamTitle: string;
  captainId: number;
  teamUsers: User[];
  accepted: boolean;
  isAlreadyInTeam: boolean;

  onAcceptOrDenyHandler: () => void;
}

const styles = makeStyles({
  emptyTeamUsersPhotos: {
    '& .UsersStack__text': {
      margin: 0,
    },
  },
  richCell: {
    '& .Button': {
      cursor: 'pointer',
    },
  },
});
const InviteCard: React.FC<InviteCardProps> = ({ ...props }) => {
  const [inviteActionRemote] = useMutation<InviteActionMutation, InviteActionMutation.Arguments>(inviteActionMutation);

  const classes = styles();
  const { teamTitle, captainId, teamUsers, onAcceptOrDenyHandler, teamId, accepted, isAlreadyInTeam } = props;
  const captain = teamUsers.find((user) => user.vkUserId === captainId);
  const teamUsersExceptCaptain = teamUsers.filter((user) => user.vkUserId !== captainId);
  const teamUsersPhotos = teamUsersExceptCaptain.map((user) => user.photo);
  const openSnackar = useSnackbar();

  const [loading, setLoading] = useState<{ loading: boolean; action: 'yes' | 'no' }>({ loading: false, action: 'yes' });

  useEffect(() => {
    if (accepted) {
      setLoading({ ...loading, loading: false });
    }
  }, [accepted]);

  const inviteActionsHandler = (answer: boolean, teamId: number) => {
    if (accepted && answer) {
      return;
    } else if (answer && isAlreadyInTeam) {
      openSnackar(<ErrorRetrySnackbar text="Вы уже состоите в команде" />);
    } else {
      setLoading({ ...loading, loading: true, action: answer ? 'yes' : 'no' });
      inviteActionRemote({
        variables: {
          params: { answer, teamId },
        },
      })
        .then((data) => {
          if (data.data?.inviteAction) {
            onAcceptOrDenyHandler();
          }
        })
        .catch((e) => {
          setLoading({ ...loading, loading: false });
          openSnackar(<ErrorRetrySnackbar text={e.message.slice(15, -1)} />);
        });
    }
  };

  return (
    <RichCell
      className={classes.richCell}
      disabled
      before={<Avatar size={72} src={captain?.photo} />}
      caption={teamTitle}
      bottom={
        <UsersStack
          className={clsx(teamUsersExceptCaptain.length < 1 && classes.emptyTeamUsersPhotos)}
          photos={teamUsersPhotos}
        >
          {teamUsersExceptCaptain.length > 0
            ? `${teamUsersExceptCaptain.length} ${declOfNum(teamUsersExceptCaptain.length, [
                'участник',
                'участника',
                'участников',
              ])}`
            : 'Еще нет участников'}
        </UsersStack>
      }
      actions={
        <>
          <Button
            disabled={accepted}
            before={accepted && <Icon16Done />}
            onClick={() => inviteActionsHandler(true, teamId)}
          >
            {loading.loading && loading.action === 'yes' ? (
              <Spinner style={{ color: 'var(--button_primary_foreground)', width: 56.35 }} size="small" />
            ) : accepted ? (
              'Принято'
            ) : (
              'Принять'
            )}
          </Button>
          {accepted ? null : (
            <Button onClick={() => inviteActionsHandler(false, teamId)} mode="secondary">
              {loading.loading && loading.action === 'no' ? (
                <Spinner style={{ width: 56.35 }} size="small" />
              ) : (
                'Отклонить'
              )}
            </Button>
          )}
        </>
      }
    >
      {`${captain?.firstName} ${captain?.lastName}`}
    </RichCell>
  );
};

export default React.memo(InviteCard);
