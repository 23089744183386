import React, { useState } from 'react';
import {
  PanelHeader,
  FormLayout,
  FormLayoutGroup,
  Input,
  SelectMimicry,
  Select,
  PanelHeaderButton,
} from '@vkontakte/vkui';
import { makeStyles } from '@material-ui/styles';
import { Theme } from 'src/theme';
import { useSelector, useActions } from 'src/hooks';
import useQueryFlag from 'src/hooks/useQueryFlag';
import { RootRoute } from 'src/router';
import vkBridge from '@vkontakte/vk-bridge';
import { ModalId } from '@overrided-vkui/overlay/types';
import RegistrationModalCities from '../atomic/modals/RegistrationModalCities';
import RegistrationModalCountries from '../atomic/modals/RegistrationModalCountries';
import { useMutation } from '@apollo/react-hooks';
import { UpdateUserMutation, updateUserMutation } from 'src/types';
import { useSnackbar } from '@overrided-vkui';
import SuccessSnackbar from '../atomic/snackbars/SuccessSnackbar';
import ErrorRetrySnackbar from '../atomic/snackbars/ErrorRetrySnackbar';
import { userActions } from 'src/redux/reducers/user';
import { RegistrationFormProps, validateForm, validationInitial } from './utils/validation';
import { ValidatedFieldsProps } from './types/types';

const styles = makeStyles(
  (theme: Theme) => ({
    root: {
      position: 'relative',
      marginTop: 7,
    },
    header: {
      '& .PanelHeader__fixed': {
        backgroundColor: theme.palette.header.background,
      },
      '& .PanelHeader__content': {
        color: theme.palette.header.color,
      },
      '& .CellButton__content': {
        color: theme.palette.header.color,
      },
    },
  }),
  { classNamePrefix: 'ProfilePanel' },
);

const ProfilePanel = () => {
  const classes = styles();
  const user = useSelector((state) => state.user)!;
  const fields = useSelector((state) => state.app.fields);
  const settings = useSelector((state) => state.settings);
  const [updateUserRemote] = useMutation<UpdateUserMutation, UpdateUserMutation.Arguments>(updateUserMutation);
  const setUserAction = useActions(userActions.setUser);
  const openSnackbar = useSnackbar();
  const [selectCountryModalOpened, openSelectCountryModal, closeSelectCountryModal] = useQueryFlag(
    RootRoute.PROFILE,
    'selectCountryModal',
  );

  const [selectCityModalOpened, openSelectCityModal, closeSelectCityModal] = useQueryFlag(
    RootRoute.PROFILE,
    'selectCityModal',
  );

  const getCountries = async () => {
    const { access_token } = await vkBridge.send('VKWebAppGetAuthToken', { app_id: 7543093, scope: '' });
    const { response } = await vkBridge.send('VKWebAppCallAPIMethod', {
      method: 'database.getCountries',
      params: { v: '5.118', access_token },
    });

    setInitialCountries(response.items);
  };

  const getCities = async (countryId = 1) => {
    const { access_token } = await vkBridge.send('VKWebAppGetAuthToken', { app_id: 7543093, scope: '' });
    const { response } = await vkBridge.send('VKWebAppCallAPIMethod', {
      method: 'database.getCities',
      params: { v: '5.118', access_token, country_id: countryId },
    });

    setInitialCitites(response.items);
  };

  const [form, setForm] = useState<RegistrationFormProps>({
    selectedCity: user.city,
    selectedCountry: user.country,
    firstName: user.firstName,
    lastName: user.lastName,
    age: user.age.toString(),
    work: user.work,
    field: user.field,
    email: user.email,
    onlineStatus: user.onlineStatus,
  });

  const [validatedFields, setValidatedFields] = useState<ValidatedFieldsProps>(validationInitial);
  const [isFormChanged, setIsFormChanged] = useState(false);

  const [initialCountries, setInitialCountries] = useState<{ id: number; title: string }[]>([]);
  const [initialCitites, setInitialCitites] = useState<{ id: number; title: string }[]>([]);

  const openModal = async (id: ModalId) => {
    if (id === 'COUNTRY') {
      await getCountries();
      openSelectCountryModal();
    } else if (id === 'CITY') {
      await getCities(form.selectedCountry && form.selectedCountry.id ? form.selectedCountry.id : 1);
      openSelectCityModal();
    }
  };

  const onChangeCountry = (selected: { id: number; title: string }) => {
    setIsFormChanged(true);
    setForm({ ...form, selectedCountry: selected, selectedCity: { id: 1, title: '' } });
  };
  const onChangeCity = (selected: { id: number; title: string }) => {
    setIsFormChanged(true);
    setForm({ ...form, selectedCity: selected });
    setInitialCitites([]);
  };

  const onSubmitHandler = () => {
    const { status, newValidatedObject } = validateForm(form);

    setValidatedFields({ ...validatedFields, ...newValidatedObject });
    if (!status) {
      return;
    }
    updateUserRemote({
      variables: {
        params: {
          age: Number(form.age),
          city: { id: form.selectedCity.id, title: form.selectedCity.title },
          country: form.selectedCountry,
          email: form.email,
          field: form.field,
          firstName: form.firstName,
          lastName: form.lastName,
          work: form.work,
          onlineStatus: form.onlineStatus,
          checkbox1: true,
          checkbox2: true,
          checkbox3: true,
        },
      },
    })
      .then((data) => {
        if (data.data?.updateUser) {
          setUserAction(data.data.updateUser);
          openSnackbar(<SuccessSnackbar text={'Данные успешно обновлены'} />);
          setValidatedFields({ ...validatedFields, ...validationInitial });
          setIsFormChanged(false);
        } else {
          openSnackbar(<ErrorRetrySnackbar text={'Не удалось обновить данные'} />);
        }
      })
      .catch((e) => {
        openSnackbar(<ErrorRetrySnackbar text={e.message} />);
      });
  };

  return (
    <>
      <PanelHeader
        left={
          <PanelHeaderButton
            disabled={!isFormChanged}
            onClick={onSubmitHandler}
            style={{ color: 'white', opacity: isFormChanged ? 1 : 0.5, cursor: 'pointer' }}
          >
            Сохранить
          </PanelHeaderButton>
        }
        separator={false}
        className={classes.header}
      >
        Профиль
      </PanelHeader>
      <FormLayout>
        <FormLayoutGroup
          status={validatedFields.firstName.status}
          bottom={validatedFields.firstName.status === 'error' && validatedFields.firstName.text}
          top="Имя"
        >
          <Input
            status={validatedFields.firstName.status}
            onChange={(e) => {
              setForm({ ...form, firstName: e.target.value });
              setIsFormChanged(true);
            }}
            value={form.firstName}
            type="text"
            placeholder="Введите имя"
          />
        </FormLayoutGroup>
        <FormLayoutGroup
          status={validatedFields.lastName.status}
          bottom={validatedFields.lastName.status === 'error' && validatedFields.lastName.text}
          top="Фамилия"
        >
          <Input
            status={validatedFields.lastName.status}
            onChange={(e) => {
              setForm({ ...form, lastName: e.target.value });
              setIsFormChanged(true);
            }}
            value={form.lastName}
            type="text"
            placeholder="Введите фамилию"
          />
        </FormLayoutGroup>
        <FormLayoutGroup
          status={validatedFields.age.status}
          bottom={validatedFields.age.status === 'error' && validatedFields.age.text}
          top="Возраст"
        >
          <Input
            status={validatedFields.age.status}
            onChange={(e) => {
              setForm({ ...form, age: e.target.value });
              setIsFormChanged(true);
            }}
            value={form.age}
            min={1}
            max={100}
            type="number"
            placeholder="Введите ваш возраст"
          />
        </FormLayoutGroup>
        <FormLayoutGroup top="Формат участия" status={validatedFields.onlineStatus.status}>
          <Select
            value={form.onlineStatus ? 1 : 0}
            onChange={(e) => {
              setForm({ ...form, onlineStatus: !!Number(e.target.value) });
              setIsFormChanged(true);
            }}
            status={validatedFields.onlineStatus.status}
          >
            <option value={1}>Онлайн</option>
            {settings?.checkboxOffline ? <option value={0}>Офлайн на площадке</option> : null}
          </Select>
        </FormLayoutGroup>
        <FormLayoutGroup
          status={validatedFields.email.status}
          bottom={validatedFields.email.status === 'error' && validatedFields.email.text}
          top="E-mail"
        >
          <Input
            status={validatedFields.email.status}
            onChange={(e) => {
              setForm({ ...form, email: e.target.value });
              setIsFormChanged(true);
            }}
            value={form.email}
            type="email"
            placeholder="Введите вашу почту"
          />
        </FormLayoutGroup>
        <FormLayoutGroup
          status={validatedFields.selectedCountry.status}
          bottom={validatedFields.selectedCountry.status === 'error' && validatedFields.selectedCountry.text}
          top="Страна"
        >
          <SelectMimicry
            status={validatedFields.selectedCountry.status}
            onClick={() => openModal('COUNTRY')}
            top="Страна"
            placeholder="Выберите страну"
          >
            {form.selectedCountry ? form.selectedCountry.title : ''}
          </SelectMimicry>
        </FormLayoutGroup>
        <FormLayoutGroup
          status={validatedFields.selectedCity.status}
          bottom={validatedFields.selectedCity.status === 'error' && validatedFields.selectedCity.text}
          top="Город"
        >
          <SelectMimicry
            status={validatedFields.selectedCity.status}
            onClick={() => openModal('CITY')}
            top="Город"
            placeholder="Выберите город"
          >
            {form.selectedCity ? form.selectedCity.title : ''}
          </SelectMimicry>
        </FormLayoutGroup>
        <FormLayoutGroup
          status={validatedFields.work.status}
          bottom={validatedFields.work.status === 'error' && validatedFields.work.text}
          top="Место работы или учёбы"
        >
          <Input
            status={validatedFields.work.status}
            onChange={(e) => {
              setForm({ ...form, work: e.target.value });
              setIsFormChanged(true);
            }}
            value={form.work}
            type="text"
            placeholder="Место работы или учёбы"
          />
        </FormLayoutGroup>
        <FormLayoutGroup
          status={validatedFields.field.status}
          bottom={validatedFields.field.status === 'error' && validatedFields.field.text}
          top="Специализация"
        >
          <Select
            status={validatedFields.field.status}
            onChange={(e) => {
              const choosenIndex = e.target.selectedIndex;
              const childNodes = e.target.childNodes;
              const choosenOption = childNodes[choosenIndex] as HTMLElement;
              const id = choosenOption.dataset.id!;
              setForm({ ...form, field: { id, title: e.target.value } });
              setIsFormChanged(true);
            }}
            value={form.field.title}
          >
            <option disabled value="">
              Выберите специализацию
            </option>
            {fields.map((field) => (
              <option data-id={field.id} key={field.id} value={field.title}>
                {field.title}
              </option>
            ))}
          </Select>
        </FormLayoutGroup>
      </FormLayout>
      {initialCountries.length > 0 && (
        <RegistrationModalCountries
          modalId="COUNTRY"
          onClose={closeSelectCountryModal}
          modalOpened={selectCountryModalOpened}
          setSelected={(selected) => onChangeCountry(selected)}
          selected={form.selectedCountry}
          countries={initialCountries}
        />
      )}
      {initialCitites.length > 0 && (
        <RegistrationModalCities
          modalId="CITY"
          onClose={() => {
            closeSelectCityModal();
            setInitialCitites([]);
          }}
          modalOpened={selectCityModalOpened}
          setSelected={(selected) => onChangeCity(selected)}
          selected={form.selectedCity}
          cities={initialCitites}
          countryId={form.selectedCountry && form.selectedCountry.id ? form.selectedCountry.id : 1}
        />
      )}
    </>
  );
};

export default React.memo(ProfilePanel);
