/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useCallback, useRef } from 'react';
import { Modal, useSnackbar } from '@overrided-vkui';
import { ModalId } from '@overrided-vkui/overlay/types';
import { Search, Button, List, Cell, Avatar, Spinner, Input, Text, Div } from '@vkontakte/vkui';
import vkBridge, { Insets } from '@vkontakte/vk-bridge';
import { useSelector } from 'src/hooks';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import { useMutation } from '@apollo/react-hooks';
import { InviteUserMutation, inviteUserMutation, SignFriend, InviteInTeamParamsInput } from 'src/types';
import ErrorRetrySnackbar from '../snackbars/ErrorRetrySnackbar';
import { throttle } from 'throttle-debounce';
import { GraphQLError } from 'graphql';

export interface CreateInvitesProps {
  onClose: () => void;
  modalOpened: boolean;
  modalId: ModalId;
  fetchStatus: boolean;
  fetchStatusHandler: (status: boolean) => void;
  currentUsersQty: number;
  maxUsersQty: number;
  onInviteHandler: () => void;
}

interface Friend {
  can_access_closed?: boolean;
  first_name: string;
  id: number;
  is_closed?: boolean;
  last_name: string;
  online?: number;
  photo_100: string;
  track_code?: string;
}
const styles = makeStyles({
  fixedModalFooterContainer: {
    height: 120,
    backgroundColor: 'var(--background_content)',
    width: '100%',
    zIndex: 3,
    paddingBottom: (props: { insets: Insets }) => `${props.insets.bottom >= 150 ? 0 : props.insets.bottom + 5}px`,
    position: 'sticky',
    bottom: 0,
    paddingTop: 1,
    boxShadow: '0px 0px 24px rgba(0, 0, 0, 0.16), 0px 0px 2px rgba(0, 0, 0, 0.08)',
    '& .FormField': {
      margin: '12px 12px',
    },
  },
  subTitlteText: {
    fontSize: 13,
    color: 'var(--attach_picker_tab_inactive_text)',
    fontWeight: 400,
  },
  cellDisabled: {
    opacity: 0.5,
  },
  cellActive: {
    opacity: 1,
  },
  modal: {
    '& .ModalPage__content-in': {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
    },
  },
});

const CreateInvitesModal: React.FC<CreateInvitesProps> = ({ ...props }) => {
  const insets = useSelector((state) => state.device.currentInsets);
  const classes = styles({ insets });

  const [sendIvitesRemote] = useMutation<InviteUserMutation, InviteUserMutation.Arguments>(inviteUserMutation);
  const openSnackbar = useSnackbar();
  const {
    modalId,
    modalOpened,
    onClose,
    fetchStatus,
    fetchStatusHandler,
    maxUsersQty,
    currentUsersQty,
    onInviteHandler,
  } = props;

  const [loading, setLoading] = useState(false);
  const [friends, setFriends] = useState<Friend[]>([]);
  const [selectedFriends, setSelectedFriends] = useState<number[]>([]);
  const [disabledMode, setDisabledMode] = useState(false);
  const [sendingInvites, setSendingInvites] = useState(false);
  const [inviteMessage, setInviteMessage] = useState('');
  const [initialMode, setInitialMode] = useState(true);
  const [refuseShare, setRefuseShare] = useState(false);
  const [accessToken, setAccessToken] = useState('');
  const [plug, setPlug] = useState(false);

  const vkUser = useSelector((state) => state.vkUser);

  const allFriendsRef = useRef<any>(null);
  const appFriendsRef = useRef<any>(null);

  const getUserFriends = useCallback(() => {
    setLoading(true);
    vkBridge
      .send('VKWebAppGetAuthToken', { app_id: 7543093, scope: 'friends' })
      .then(async (data) => {
        if (data.access_token) {
          const access_token = data.access_token;
          const { response } = await vkBridge.send('VKWebAppCallAPIMethod', {
            method: 'friends.get',
            params: { v: '5.118', access_token, user_id: vkUser.id, order: 'hints', fields: 'photo_100' },
          });

          const appFriends = await vkBridge.send('VKWebAppCallAPIMethod', {
            method: 'friends.getAppUsers',
            params: { v: '5.118', access_token },
          });

          const appFriendsIds = appFriends.response.join(',');

          const appFriendsData = await vkBridge.send('VKWebAppCallAPIMethod', {
            method: 'users.get',
            params: { v: '5.118', access_token, fields: 'photo_100', user_ids: appFriendsIds },
          });

          const formatedAppFriends = appFriendsData.response;
          const allFriends = response.items;

          allFriendsRef.current = allFriends;
          appFriendsRef.current = formatedAppFriends;

          setFriends(formatedAppFriends.slice(0, 50));
          setRefuseShare(false);
          fetchStatusHandler(false);
          setLoading(false);
          setAccessToken(access_token);
        }
      })
      .catch(() => {
        setLoading(false);
        fetchStatusHandler(false);
        setRefuseShare(true);
      });
  }, [fetchStatusHandler, vkUser.id]);

  const sendInvites = async () => {
    try {
      setSendingInvites(true);
      const friendsIdsToString = selectedFriends.join(',');
      const { response } = await vkBridge.send('VKWebAppCallAPIMethod', {
        method: 'friends.areFriends',
        params: { v: '5.118', access_token: accessToken, user_ids: friendsIdsToString, need_sign: 1 },
      });

      const responseToSendToServer: SignFriend[] = response.map(
        (friend: { user_id: number; sign: string; friend_status: boolean }) => ({
          id: friend.user_id,
          friendStatus: friend.friend_status,
          sign: friend.sign,
        }),
      );

      sendIvitesRemote({
        variables: {
          params: {
            friends: responseToSendToServer,
            message: inviteMessage,
          },
        },
      })
        .then(({ data }) => {
          if (data?.inviteUser) {
            onInviteHandler();
            onClose();
            setSendingInvites(false);
            setSelectedFriends([]);
          }
        })
        .catch((e: GraphQLError) => {
          openSnackbar(<ErrorRetrySnackbar text={e.message} />);
          setSendingInvites(false);
        });
    } catch (e) {
      openSnackbar(<ErrorRetrySnackbar text={e.message} />);
    }
  };

  useEffect(() => {
    if (fetchStatus) {
      getUserFriends();
    }
  }, [fetchStatus]);

  useEffect(() => {
    selectedFriends.length >= maxUsersQty - currentUsersQty && setDisabledMode(true);

    return () => setDisabledMode(false);
  }, [disabledMode, selectedFriends, currentUsersQty, maxUsersQty]);

  const onFriendsSelectedChange = (friend: Friend) => {
    const isFriendExistsInArr = selectedFriends.some((friendId) => friend.id === friendId);
    if (disabledMode && !isFriendExistsInArr) {
      return;
    }
    if (isFriendExistsInArr) {
      setSelectedFriends(selectedFriends.filter((friendId) => friendId !== friend.id));
    } else {
      setSelectedFriends([...selectedFriends, friend.id]);
    }
  };

  const onSearchHandler: any = useCallback(
    throttle(600, (q: string) => {
      if (allFriendsRef && allFriendsRef.current && appFriendsRef && appFriendsRef.current) {
        if (q.trim().length < 1) {
          setInitialMode(true);
          setFriends(appFriendsRef.current);
          return;
        }

        const isFullName = /\s/.test(q.trim());
        const filteredFriends = allFriendsRef.current.filter((friend: Friend) => {
          if (isFullName) {
            return (
              friend.first_name.concat(friend.last_name).toLowerCase().includes(q.split(' ').join('').toLowerCase()) ||
              friend.last_name.concat(friend.first_name).toLowerCase().includes(q.split(' ').join('').toLowerCase())
            );
          } else {
            return (
              friend.first_name.toLowerCase().substring(0, q.trim().length) === q.toLowerCase().trim() ||
              friend.last_name.toLowerCase().substring(0, q.trim().length) === q.toLowerCase().trim()
            );
          }
        });

        if (filteredFriends.length < 1) {
          setInitialMode(false);
          setPlug(true);
        } else if (filteredFriends.length > 50) {
          setPlug(false);
          setInitialMode(false);
          setFriends(filteredFriends.splice(0, 50));
        } else {
          setPlug(false);
          setInitialMode(false);
          setFriends(filteredFriends);
        }
      } else {
        return;
      }
    }),
    [],
  );

  const refuseToShareFriends = (
    <Div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <Text style={{ color: 'var(--attach_picker_tab_inactive_text)', marginBottom: 10 }} weight="regular">
        Для отправки приглашений необходимо дать разрешение
      </Text>
      <Button onClick={() => getUserFriends()}>Дать разрешение</Button>
    </Div>
  );

  return (
    <Modal
      className={classes.modal}
      id={modalId}
      show={modalOpened}
      onClose={() => {
        onClose();
        setSelectedFriends([]);
        setInitialMode(true);
        appFriendsRef && appFriendsRef.current && setFriends(appFriendsRef.current);
      }}
      title={
        <div>
          Приглашение
          <div className={classes.subTitlteText}>{`Выбрано ${selectedFriends.length} из ${
            maxUsersQty - currentUsersQty
          }`}</div>
        </div>
      }
      fullHeight
      headerShaddow="never"
      subHeader={
        <Search
          placeholder="Поиск по всем друзьям"
          onChange={(e) => {
            onSearchHandler(e.target.value);
          }}
        />
      }
      left={
        <Button disabled={selectedFriends.length < 1} onClick={() => setSelectedFriends([])} mode="tertiary" size="m">
          Очистить
        </Button>
      }
    >
      <div>
        {refuseShare ? (
          refuseToShareFriends
        ) : loading ? (
          <Spinner style={{ marginTop: 20 }} />
        ) : (
          <>
            <List>
              {initialMode && (
                <Div>
                  <Text style={{ color: 'var(--attach_picker_tab_inactive_text)' }} weight="regular">
                    Друзья в приложении:
                  </Text>
                </Div>
              )}
              {initialMode && friends.length < 1 ? (
                <Div style={{ color: 'var(--attach_picker_tab_inactive_text)', paddingTop: 0 }}>
                  Друзья еще не установили приложение
                </Div>
              ) : !initialMode && plug ? (
                <Div style={{ color: 'var(--attach_picker_tab_inactive_text)' }}>Ни одного друга не найдено</Div>
              ) : (
                friends.map((friend) => (
                  <Cell
                    className={clsx(
                      disabledMode && !~selectedFriends.indexOf(friend.id) ? classes.cellDisabled : classes.cellActive,
                    )}
                    checked={~selectedFriends.indexOf(friend.id) ? true : false}
                    onChange={() => onFriendsSelectedChange(friend)}
                    key={friend.id}
                    selectable
                    before={<Avatar size={40} src={friend.photo_100} />}
                  >
                    {`${friend.first_name} ${friend.last_name}`}
                  </Cell>
                ))
              )}
            </List>
          </>
        )}
      </div>
      <div className={classes.fixedModalFooterContainer}>
        <Input
          value={inviteMessage}
          onChange={(e) => setInviteMessage(e.target.value)}
          placeholder="Добавить сообщение"
        />
        <div style={{ margin: '0 12px' }}>
          <Button disabled={selectedFriends.length < 1} onClick={sendInvites} size="xl">
            {sendingInvites ? 'Отправляем приглашение...' : 'Отправить приглашение'}
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default React.memo(CreateInvitesModal);
