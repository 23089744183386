import React from 'react';
import View, { ViewProps } from '@vkontakte/vkui/dist/components/View/View';
import { Panel } from '@vkontakte/vkui';
import InvitesPanel from '../panels/InvitesPanel';
import { makeStyles } from '@material-ui/styles';

const styles = makeStyles(
  {
    root: {
      '& .Panel__in': {
        paddingBottom: '48px !important',
      },
    },
  },
  { classNamePrefix: 'profile' },
);

const InvitesView: React.FC<Omit<ViewProps, 'activePanel'>> = (viewProps) => {
  const classes = styles();
  return (
    <View {...viewProps} activePanel="invites.panel">
      <Panel className={classes.root} id="invites.panel">
        <InvitesPanel />
      </Panel>
    </View>
  );
};

export default React.memo(InvitesView);
