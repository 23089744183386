import { ofType, unionize, UnionOf } from 'unionize';
import { unionizeConfig } from '../utils';
import { Team } from 'src/types';

export type TeamReducerState = Team | null;

export const teamActions = unionize(
  {
    setTeam: ofType<Team>(),
  },
  unionizeConfig,
);

type TeamActions = UnionOf<typeof teamActions>;

const initialState: TeamReducerState = null;

export function teamReducer(state: TeamReducerState = initialState, action: TeamActions) {
  return teamActions.match(action, {
    setTeam: (team) => ({
      ...state,
      ...team,
    }),
    default: () => state,
  });
}
