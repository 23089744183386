import { ofType, unionize, UnionOf } from 'unionize';
import { unionizeConfig } from '../utils';
import { User } from 'src/types';

export type UserReducerState = User | null;

export const userActions = unionize(
  {
    setUser: ofType<User>(),
  },
  unionizeConfig,
);

type UserActions = UnionOf<typeof userActions>;

const initialState: UserReducerState = null;

export function userReducer(state: UserReducerState = initialState, action: UserActions) {
  return userActions.match(action, {
    setUser: (user) => ({
      ...state,
      ...user,
    }),
    default: () => state,
  });
}
