import React from 'react';
import View, { ViewProps } from '@vkontakte/vkui/dist/components/View/View';
import { Panel } from '@vkontakte/vkui';
import { makeStyles } from '@material-ui/styles';
import AdminPanel from '../panels/AdminPanel';

const styles = makeStyles(
  {
    root: {
      '& .Panel__in': {
        paddingBottom: '48px !important',
      },
    },
  },
  { classNamePrefix: 'admin' },
);
const AdminView: React.FC<Omit<ViewProps, 'activePanel'>> = (viewProps) => {
  const classes = styles();
  return (
    <View {...viewProps} activePanel="admin.panel">
      <Panel className={classes.root} id="admin.panel">
        <AdminPanel />
      </Panel>
    </View>
  );
};

export default React.memo(AdminView);
