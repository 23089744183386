import createRouter5 from 'router5';
import loggerPlugin from 'router5-plugin-logger';
import browserPlugin from 'router5-plugin-browser';

export enum RootRoute {
  TEAM = 'team',
  INVITES = 'invites',
  PROFILE = 'profile',
  ADMIN = 'admin',
  REGISTRATION = 'registration',
  REGISTRATION_FORM = 'registration.form',
  REGISTRATION_ERROR = 'registrationerror',
  APP_OFF = 'app-off',
}

export const createRouter = (defaultRoute: string, invite: boolean) => {
  const router = createRouter5(
    [
      {
        name: RootRoute.TEAM,
        path: '/team',
      },
      {
        name: RootRoute.INVITES,
        path: '/invites',
      },
      {
        name: RootRoute.PROFILE,
        path: '/profile',
      },
      {
        name: RootRoute.ADMIN,
        path: '/admin',
      },
      {
        name: RootRoute.REGISTRATION,
        path: '/registration',
      },
      {
        name: RootRoute.REGISTRATION_FORM,
        path: '/registration-form',
      },
      {
        name: RootRoute.REGISTRATION_ERROR,
        path: '/registrationerror',
      },
      {
        name: RootRoute.APP_OFF,
        path: '/app-off',
      },
    ],
    { defaultRoute, queryParamsMode: 'loose' },
  );

  router.usePlugin(loggerPlugin);
  router.usePlugin(
    browserPlugin({
      useHash: true,
      base: window.location.search,
    }),
  );

  router.start();

  // fixes desktop safary first back navigation problem
  const route = router.getState();

  console.log(route);

  if (route) {
    router.navigate(route.name, { ...route.params, fix: 'safari', invite }, { force: true });
  }

  return router;
};
