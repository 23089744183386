import React, { useCallback, useState, useRef } from 'react';
import { Search, List, Cell } from '@vkontakte/vkui';
import Modal from '../../overridedVkUi/Modal/Modal';
import vkBridge from '@vkontakte/vk-bridge';
import Icon24Done from '@vkontakte/icons/dist/24/done';
import { ModalId } from '../../overridedVkUi/overlay/types';
import { throttle } from 'throttle-debounce';

export interface RegistrationModalProps {
  onClose: () => void;
  modalOpened: boolean;
  modalId: ModalId;
  setSelected: (selected: { id: number; title: string }) => void;
  selected: { id: number; title: string };
  cities: { id: number; title: string }[];
  countryId: number;
}

const RegistrationModal: React.FC<RegistrationModalProps> = ({
  onClose,
  modalOpened,
  modalId,
  setSelected,
  selected,
  cities,
  countryId,
}) => {
  const getCitiesByQuery: any = useCallback(
    throttle(1500, async (countryId = 1, q: string) => {
      const { access_token } = await vkBridge.send('VKWebAppGetAuthToken', { app_id: 7543093, scope: '' });
      const { response } = await vkBridge.send('VKWebAppCallAPIMethod', {
        method: 'database.getCities',
        params: { v: '5.118', access_token, country_id: countryId, q },
      });

      setSearchedItems(response.items);
    }),
    [],
  );

  const inititalItems = cities;
  const [searchedItems, setSearchedItems] = useState<{ id: number; title: string }[]>(cities);
  const [search, setSearch] = useState<string>('');

  const [queryMode, setQueryMode] = useState<boolean>(false);

  const searchRef = useRef<any>(null);

  const onDifferentCityHandler = () => {
    searchRef.current.focus();
    setSearchedItems([]);
    setQueryMode(true);
    getCitiesByQuery(countryId, search);
  };

  const onSearchHandler = async (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
    if (!queryMode) {
      if (e.target.value.trim().length > 0) {
        setSearchedItems(
          inititalItems.filter(({ title }) => title.toLowerCase().includes(e.target.value.toLowerCase())),
        );
      } else {
        setSearchedItems(inititalItems);
      }
    } else {
      if (e.target.value.trim().length > 0) {
        getCitiesByQuery(countryId, e.target.value);
      } else {
        setSearchedItems(inititalItems);
      }
    }
  };
  return (
    <Modal
      id={modalId}
      show={modalOpened}
      onClose={onClose}
      title={modalId === 'CITY' ? 'Город' : 'Страна'}
      fullHeight
      headerShaddow="never"
      subHeader={
        <Search
          getRef={searchRef}
          value={search}
          onChange={(e) => {
            onSearchHandler(e);
          }}
        />
      }
    >
      <List>
        {searchedItems.map((country) => (
          <Cell
            onClick={() => {
              setSelected(country);
              onClose();
            }}
            asideContent={
              selected && country && country.title === selected.title ? <Icon24Done fill="var(--accent)" /> : null
            }
            key={country.id}
          >
            {country.title}
          </Cell>
        ))}
        {!queryMode && <Cell onClick={onDifferentCityHandler}>Другой</Cell>}
      </List>
    </Modal>
  );
};

export default React.memo(RegistrationModal);
