import React, { useState } from 'react';
import { PanelHeader, Div, Link, Group, Input, Header, Button, CellButton, Switch, File } from '@vkontakte/vkui';
import moment from 'moment';
import { makeStyles } from '@material-ui/styles';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { useRouteNode } from 'react-router5';
import { RootRoute } from '../../router';
import { useActions, useSelector } from '../../hooks';
import { blobToBase64 } from '../../utils';
import { Theme } from 'src/theme';
import {
  TableQuery,
  tableQuery,
  ChangeAppStatusMutation,
  changeAppStatusMutation,
  ChangeAppContentMutation,
  changeAppContentMutation,
  ChangeRegisterStatusMutation,
  changeRegisterStatusMutation,
  ChangeOfflineRegisterStatusMutation,
  changeOfflineRegisterStatusMutation,
  ChangeRegisterContentMutation,
  changeRegisterContentMutation,
  ChangeOnboardingContentMutation,
  changeOnboardingContentMutation,
  ChangeUnavailableMutation,
  changeUnavailableMutation,
  UpdateAdminMutation,
  updateAdminMutation,
  DeleteUsersMutation,
  deleteUsersMutation,
} from 'src/types/gql';
import { AppLoadingView } from '../views/AppLoadingView';
import { settingsActions } from '../../redux/reducers/settings';

const styles = makeStyles(
  (theme: Theme) => ({
    header: {
      '& .PanelHeader__fixed': {
        backgroundColor: theme.palette.header.background,
      },
      '& .PanelHeader__content': {
        color: theme.palette.header.color,
      },
    },
    link: {
      '&:first-child': {
        marginBottom: 20,
      },
      '&:active': {
        opacity: 0.7,
      },
    },
  }),
  { classNamePrefix: 'adminPanel' },
);

const AdminPanel = () => {
  const { router } = useRouteNode('');

  const classes = styles();
  const settings = useSelector((state) => state.settings);
  const setSettings = useActions(settingsActions.setSettings);

  const tables = useQuery<TableQuery, TableQuery.Arguments>(tableQuery, {
    fetchPolicy: 'network-only',
  });

  const [admins, setAdmins] = useState('');
  const [users, setUsers] = useState('');

  const [appStatus, setAppStatus] = useState(!!settings?.appStatus);
  const [appImg, setAppImg] = useState(settings?.appImg || '');
  const [appTitle, setAppTitle] = useState(settings?.appTitle || '');
  const [appDescription, setAppDescription] = useState(settings?.appDescription || '');

  const [registerStatus, setRegisterStatus] = useState(!!settings?.registerStatus);
  const [registerLink, setRegisterLink] = useState(settings?.registerLink || '');
  const [registerImg, setRegisterImg] = useState(settings?.registerImg || '');
  const [registerTitle, setRegisterTitle] = useState(settings?.registerTitle || '');
  const [registerDescription, setRegisterDescription] = useState(settings?.registerDescription || '');
  const [checkboxOffline, setCheckboxOffline] = useState(settings?.checkboxOffline);
  const [dateUnavailable, setDateUnavailable] = useState(moment(settings?.dateUnavailable).format('YYYY-MM-DD') || '');

  const [onboardingImg, setOnboardingImg] = useState(settings?.onboardingImg || '');
  const [onboardingTitle, setOnboardingTitle] = useState(settings?.onboardingTitle || '');
  const [onboardingDescription, setOnboardingDescription] = useState(settings?.onboardingDescription || '');

  const updateSettings = (overwrite: any = {}) => {
    const settings = {
      appStatus,
      appImg,
      appTitle,
      appDescription,
      checkboxOffline: checkboxOffline!,
      dateUnavailable: dateUnavailable ? moment(dateUnavailable, 'YYYY-MM-DD').toDate() : null,
      onboardingDescription,
      onboardingImg,
      onboardingTitle,
      registerDescription,
      registerImg,
      registerLink,
      registerTitle,
      ...overwrite,
    };

    setSettings(settings);
  };

  const setAppImgString = async (blob: any) => {
    const file = blob[0];
    const base64string = await blobToBase64(file);

    setAppImg(base64string);
  };

  const setRegisterImgString = async (blob: any) => {
    const file = blob[0];
    const base64string = await blobToBase64(file);

    setRegisterImg(base64string);
  };

  const setOnboardingImgString = async (blob: any) => {
    const file = blob[0];
    const base64string = await blobToBase64(file);

    setOnboardingImg(base64string);
  };

  const [changeAppStatusRemote, changeAppStatus] = useMutation<
    ChangeAppStatusMutation,
    ChangeAppStatusMutation.Arguments
  >(changeAppStatusMutation);

  const changeAppStatusHandler = async () => {
    await changeAppStatusRemote({
      variables: {
        params: {
          status: !appStatus,
        },
      },
    })
      .then((data) => {
        if (data.data?.changeAppStatus) {
          setAppStatus(!appStatus);
          updateSettings({ appStatus: !appStatus });
        }
      })
      .catch((e) => console.log('error ', e));
  };

  const [changeAppContentRemote, changeAppContent] = useMutation<
    ChangeAppContentMutation,
    ChangeAppContentMutation.Arguments
  >(changeAppContentMutation);

  const changeAppContentHandler = async () => {
    await changeAppContentRemote({
      variables: {
        params: {
          url: null,
          img: appImg,
          title: appTitle,
          description: appDescription,
        },
      },
    })
      .then((data) => {
        if (data.data?.changeAppContent) {
          updateSettings();
        }
      })
      .catch((e) => console.log('error ', e));
  };

  const [changeOfflineRegisterStatusRemote, changeOfflineRegisterStatus] = useMutation<
    ChangeOfflineRegisterStatusMutation,
    ChangeOfflineRegisterStatusMutation.Arguments
  >(changeOfflineRegisterStatusMutation);

  const changeOfflineRegisterStatusHandler = async () => {
    await changeOfflineRegisterStatusRemote({
      variables: {
        params: {
          status: !checkboxOffline,
        },
      },
    })
      .then((data) => {
        if (data.data?.changeOfflineRegisterStatus) {
          setCheckboxOffline(!checkboxOffline);
          updateSettings({ checkboxOffline: !checkboxOffline });
        }
      })
      .catch((e) => console.log('error ', e));
  };

  const [updateAdminRemote, updateAdmin] = useMutation<UpdateAdminMutation, UpdateAdminMutation.Arguments>(
    updateAdminMutation,
  );

  const updateAdminHandler = async () => {
    await updateAdminRemote({
      variables: {
        params: {
          userIds: admins,
        },
      },
    })
      .then((data) => {
        if (data.data?.updateAdmin) {
          setAdmins('');
        }
      })
      .catch((e) => console.log('error ', e));
  };

  const [deleteUsersRemote, deleteUsers] = useMutation<DeleteUsersMutation, DeleteUsersMutation.Arguments>(
    deleteUsersMutation,
  );

  const deleteUsersHandler = async () => {
    await deleteUsersRemote({
      variables: {
        params: {
          userIds: users,
        },
      },
    })
      .then((data) => {
        if (data.data?.deleteUsers) {
          setUsers('');
        }
      })
      .catch((e) => console.log('error ', e));
  };

  const [changeUnavailableRemote, changeUnavailable] = useMutation<
    ChangeUnavailableMutation,
    ChangeUnavailableMutation.Arguments
  >(changeUnavailableMutation);

  const changeUnavailableHandler = async () => {
    await changeUnavailableRemote({
      variables: {
        params: {
          dateUnavailable: dateUnavailable ? moment(dateUnavailable, 'YYYY-MM-DD').toDate() : null,
        },
      },
    })
      .then((data) => {
        if (data.data?.changeUnavailable) {
          updateSettings({ dateUnavailable: dateUnavailable ? moment(dateUnavailable, 'YYYY-MM-DD').toDate() : null });
        }
      })
      .catch((e) => console.log('error ', e));
  };

  const [changeOnboardingContentRemote, changeOnboardingContent] = useMutation<
    ChangeOnboardingContentMutation,
    ChangeOnboardingContentMutation.Arguments
  >(changeOnboardingContentMutation);

  const changeOnboardingContentHandler = async () => {
    await changeOnboardingContentRemote({
      variables: {
        params: {
          url: null,
          img: onboardingImg,
          title: onboardingTitle,
          description: onboardingDescription,
        },
      },
    })
      .then((data) => {
        if (data.data?.changeOnboardingContent) {
          updateSettings();
        }
      })
      .catch((e) => console.log('error ', e));
  };

  const [changeRegisterStatusRemote, changeRegisterStatus] = useMutation<
    ChangeRegisterStatusMutation,
    ChangeRegisterStatusMutation.Arguments
  >(changeRegisterStatusMutation);

  const changeRegisterStatusHandler = async () => {
    await changeRegisterStatusRemote({
      variables: {
        params: {
          status: !registerStatus,
        },
      },
    })
      .then((data) => {
        if (data.data?.changeRegisterStatus) {
          setRegisterStatus(!registerStatus);
          updateSettings({ registerStatus: !registerStatus });
        }
      })
      .catch((e) => console.log('error ', e));
  };

  const [changeRegisterContentRemote, changeRegisterContent] = useMutation<
    ChangeRegisterContentMutation,
    ChangeRegisterContentMutation.Arguments
  >(changeRegisterContentMutation);

  const changeRegisterContentHandler = async () => {
    await changeRegisterContentRemote({
      variables: {
        params: {
          url: registerLink,
          img: registerImg,
          title: registerTitle,
          description: registerDescription,
        },
      },
    })
      .then((data) => {
        if (data.data?.changeRegisterContent) {
          updateSettings();
        }
      })
      .catch((e) => console.log('error ', e));
  };

  if (tables.loading) {
    return <AppLoadingView />;
  } else if (tables.error) {
    return <Div>{`Произошла ошибка ${tables.error.message}`}</Div>;
  } else {
    return (
      <>
        <PanelHeader className={classes.header} separator={false}>
          Админ
        </PanelHeader>
        <Group separator={'show'} header={<Header mode="secondary">Таблицы</Header>}>
          <Div style={{ display: 'flex', flexDirection: 'column' }}>
            {tables.data?.table.map((table, i) => (
              <Link key={i} className={classes.link} download href={table.link}>
                {table.label}
              </Link>
            ))}
          </Div>
        </Group>
        <Group separator={'show'} header={<Header mode="secondary">Изменить статус администратора</Header>}>
          <Div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Input value={admins} type="text" onChange={(e) => setAdmins(e.target.value)} />
            <Button type={'submit'} onClick={updateAdminHandler}>
              Изменить
            </Button>
          </Div>
        </Group>
        <Group separator={'show'} header={<Header mode="secondary">Удалить пользователя</Header>}>
          <Div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Input value={users} type="text" onChange={(e) => setUsers(e.target.value)} />
            <Button type={'reset'} onClick={deleteUsersHandler}>
              Удалить
            </Button>
          </Div>
        </Group>
        <Group separator={'show'} header={<Header mode="secondary">Онбординг</Header>}>
          <Div>
            <img src={onboardingImg} style={{ height: 160 }} alt="Изображение не загружено" />
          </Div>
          <Div>
            <File onChange={(e) => setOnboardingImgString(e.target.files)}>Загрузить</File>
            {onboardingImg && (
              <Button type={'reset'} style={{ marginLeft: 16 }} onClick={() => setOnboardingImg('')}>
                Удалить
              </Button>
            )}
          </Div>
          <Div>
            <Group separator={'hide'} header={<Header mode="secondary">Заголовок</Header>}>
              <Input type="text" value={onboardingTitle} onChange={(e) => setOnboardingTitle(e.target.value)} />
            </Group>
          </Div>
          <Div>
            <Group separator={'hide'} header={<Header mode="secondary">Описание</Header>}>
              <Input
                type="text"
                value={onboardingDescription}
                onChange={(e) => setOnboardingDescription(e.target.value)}
              />
            </Group>
          </Div>
          <Div>
            <Button type={'submit'} onClick={changeOnboardingContentHandler}>
              Обновить
            </Button>
          </Div>
          <Div>
            <Button type="submit" onClick={() => router.navigate(RootRoute.REGISTRATION)}>
              Перейти к онбордингу
            </Button>
          </Div>
        </Group>
        <Group separator={'show'} header={<Header mode="secondary">Статус приложения</Header>}>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Div>Статус приложения</Div>
            <Div>
              <Switch
                aria-label={appStatus ? 'Выключить' : 'Включить'}
                checked={appStatus}
                onChange={changeAppStatusHandler}
              />
            </Div>
          </div>
        </Group>
        <Group separator={'show'} header={<Header mode="secondary">Заглушка при выключенном приложении</Header>}>
          <Div>
            <img src={appImg} style={{ height: 160 }} alt="Изображение не загружено" />
          </Div>
          <Div>
            <File onChange={(e) => setAppImgString(e.target.files)}>Загрузить</File>
            {appImg && (
              <Button type={'reset'} style={{ marginLeft: 16 }} onClick={() => setAppImg('')}>
                Удалить
              </Button>
            )}
          </Div>
          <Div>
            <Group separator={'hide'} header={<Header mode="secondary">Заголовок</Header>}>
              <Input type="text" value={appTitle} onChange={(e) => setAppTitle(e.target.value)} />
            </Group>
          </Div>
          <Div>
            <Group separator={'hide'} header={<Header mode="secondary">Описание</Header>}>
              <Input type="text" value={appDescription} onChange={(e) => setAppDescription(e.target.value)} />
            </Group>
          </Div>
          <Div>
            <Button type={'submit'} onClick={changeAppContentHandler}>
              Обновить
            </Button>
          </Div>
          <Div>
            <Button type="submit" onClick={() => router.navigate(RootRoute.APP_OFF)}>
              Перейти к заглушке выключенного приложения
            </Button>
          </Div>
        </Group>
        <Group separator={'show'} header={<Header mode="secondary">Статус регистрации</Header>}>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Div>Статус регистрации</Div>
            <Div>
              <Switch
                aria-label={registerStatus ? 'Выключить' : 'Включить'}
                checked={registerStatus}
                onChange={changeRegisterStatusHandler}
              />
            </Div>
          </div>
        </Group>
        <Group separator={'show'} header={<Header mode="secondary">Статус участия оффлайн</Header>}>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Div>Статус участия оффлайн</Div>
            <Div>
              <Switch
                aria-label={checkboxOffline ? 'Выключить' : 'Включить'}
                checked={checkboxOffline}
                onChange={changeOfflineRegisterStatusHandler}
              />
            </Div>
          </div>
        </Group>
        <Group separator={'show'} header={<Header mode="secondary">Заглушка при выключенной регистрации</Header>}>
          <Div>
            <img src={registerImg} style={{ height: 160 }} alt="Изображение не загружено" />
          </Div>
          <Div>
            <File onChange={(e) => setRegisterImgString(e.target.files)}>Загрузить</File>
            {registerImg && (
              <Button type={'reset'} style={{ marginLeft: 16 }} onClick={() => setRegisterImg('')}>
                Удалить
              </Button>
            )}
          </Div>
          <Div>
            <Group separator={'hide'} header={<Header mode="secondary">Заголовок</Header>}>
              <Input type="text" value={registerTitle} onChange={(e) => setRegisterTitle(e.target.value)} />
            </Group>
          </Div>
          <Div>
            <Group separator={'hide'} header={<Header mode="secondary">Описание</Header>}>
              <Input type="text" value={registerDescription} onChange={(e) => setRegisterDescription(e.target.value)} />
            </Group>
          </Div>
          <Div>
            <Group separator={'hide'} header={<Header mode="secondary">Ссылка</Header>}>
              <Input type="text" value={registerLink} onChange={(e) => setRegisterLink(e.target.value)} />
            </Group>
          </Div>
          <Div>
            <Button type={'submit'} onClick={changeRegisterContentHandler}>
              Обновить
            </Button>
          </Div>
          <Div>
            <Button type="submit" onClick={() => router.navigate(RootRoute.REGISTRATION_ERROR)}>
              Перейти к заглушке выключенной регистрации
            </Button>
          </Div>
        </Group>
        <Group separator={'show'} header={<Header mode="secondary">Дата, с которой доступна регистрация</Header>}>
          <Div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Input type={'date'} value={dateUnavailable} onChange={(e) => setDateUnavailable(e.target.value)} />
            <Button type={'submit'} onClick={changeUnavailableHandler}>
              Изменить
            </Button>
          </Div>
          <Div>
            <CellButton type={'reset'} onClick={(e) => setDateUnavailable('')}>
              Выключить
            </CellButton>
          </Div>
        </Group>
      </>
    );
  }
};

export default React.memo(AdminPanel);
