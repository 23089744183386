import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Title, Div, Text } from '@vkontakte/vkui';

import { useActions, useSelector } from '../../hooks';
import { Theme } from '../../theme';
import { deviceActions } from '../../redux/reducers/device';
import BackgroundSVG from '../../assets/onboarding-background.svg';
import VkSVG from '../../assets/vk.svg';

const styles = makeStyles(
  (theme: Theme) => ({
    root: {
      height: '100%',
      width: '100%',
      backgroundColor: '#1C3738',
      // backgroundImage: `url(${BackgroundSVG})`,
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'contain',
      backgroundPosition: 'center center',
      overflow: 'hidden',
      display: 'flex',
      alignItems: 'center',
      position: 'fixed',
      top: 0,
      right: 0,
      left: 0,
      bottom: 0,
    },
    background: {
      position: 'absolute',
      zIndex: -1,
      height: '100%',
      width: '100%',
      objectFit: 'cover',
    },
    centerBlock: {
      paddingTop: 40,
    },
    title: {
      color: 'white',
      fontSize: 53,
      fontWeight: 400,
      lineHeight: '66px',
      fontFamily: 'VK Sans Display',
    },
    city: {
      color: 'white',
      fontSize: 32,
      lineHeight: '40px',
      fontFamily: 'VK Sans Display',
    },
    text: {
      color: 'white',
      fontSize: 18,
      lineHeight: '22px',
      fontFamily: 'VK Sans Display',
      marginTop: '6vh',
      marginBottom: '4vh',
    },
    button: {
      background: 'white',
      color: '#2F80ED',
      fontSize: 17,
      fontWeight: 500,
      lineHeight: '22px',
      borderRadius: 10,
      width: '100%',
      height: 44,
      border: 'none',
      outline: 'none',
      '&:active': {
        opacity: 0.7,
      },
    },
    logo: {
      position: 'absolute',
      bottom: '8vh',
      height: '44px',
      width: '195px',
      backgroundImage: `url(${VkSVG})`,
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'contain',
      backgroundPosition: 'center center',
    },
  }),
  { classNamePrefix: 'registration' },
);

interface RegistrationProps {
  onBtnClick: any;
}

const RegistrationPanel: React.FC<RegistrationProps> = ({ onBtnClick }) => {
  const setStatusbar = useActions(deviceActions.setCurrentStatusBarMode);
  const insets = useSelector((state) => state.device.currentInsets);
  const settings = useSelector((state) => state.settings);

  useEffect(() => {
    setStatusbar({ mode: 'custom', style: { color: '#000000', style: 'light' } });
  }, [setStatusbar]);

  const classes = styles({ insets });
  return (
    <div className={classes.root}>
      <img className={classes.background} src={settings?.onboardingImg || BackgroundSVG} alt="" />
      <Div className={classes.centerBlock}>
        <Title className={classes.title} level="1" weight="bold">
          вездекод
        </Title>
        <Title className={classes.city} level="1" weight="semibold">
          {settings?.onboardingTitle || 'калининград'}
        </Title>
        <Text className={classes.text} weight="regular">
          {settings?.onboardingDescription ||
            'Зарегистрируйте свою команду на один из отборочных этапов марафона от ВКонтакте — Вездекод'}
        </Text>
        <button className={classes.button} style={{ color: '#000000' }} onClick={() => onBtnClick()}>
          Регистрация
        </button>
        {/*<div className={classes.logo} />*/}
      </Div>
    </div>
  );
};

export default React.memo(RegistrationPanel);
